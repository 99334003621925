import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { CustomTableColumn } from 'src/app/modules/reports/_models/reports-overview.model';

@Component({
	selector: 'app-report-table',
	templateUrl: './report-table.component.html',
	styleUrls: ['./report-table.component.scss'],
})
export class ReportTableComponent implements OnInit, OnChanges, OnDestroy {
	@Input() extras: any;
	@Input() public tableColumns: Array<CustomTableColumn> = [];
	@Input() public tableContentsInput: Array<any> = [];
	@Input() public fileName: string = '';
	@Input() public equalSizeColumns: boolean = false;
	@Input() public sortDir: 'ASC' | 'DESC' = 'ASC';
	@Input() public sortHeader: CustomTableColumn;
	@Input() public stickyHead = false;
	@Input() public compliant: number;
	public tableContents: Array<any> = [];
	public limit: any = 100;
	public offset: number = 0;
	public sortKey: string = '';
	private unsubscribe$: Subject<boolean> = new Subject();

	constructor(public localData: LocalDataService, public router: Router) {}

	ngOnInit(): void {
		this.setPageContents();
	}
	ngOnChanges(): void {
		if (this.sortHeader) {
			this.sortTable(this.sortHeader, false);
		}
		this.setPageContents();
	}
	ngOnDestroy() {
		this.unsubscribe$.next(true);
	}
	public changePageLimit(): void {
		this.offset = 0;
		this.setPageContents();
	}
	private setPageContents(): void {
		this.tableContents = this.localData.paginateArray(
			this.tableContentsInput,
			this.limit,
			this.offset
		);
	}
	public changePage(event: any): void {
		this.offset = (event - 1) * this.limit;
		this.setPageContents();
	}
	public redirectUser(row, column: CustomTableColumn) {
		if (column.redirectTo) {
			this.router.navigate([
				`${column.redirectTo}${
					column.redirectUrlKeyColumn
						? '/' + row[column.redirectUrlKeyColumn]
						: ''
				}`,
			]);
		}
	}
	public sortTable(
		header: CustomTableColumn,
		toggleSortDirection: boolean = true
	): void {
		this.sortKey = header.bindKey;
		if (this.sortKey === header.bindKey) {
			if (toggleSortDirection) {
				this.sortDir = this.sortDir === 'ASC' ? 'DESC' : 'ASC';
			}
		} else {
			this.sortDir = 'ASC';
		}
		if (!header.dateValue) {
			if (header.numberColumn) {
				this.tableContentsInput = this.tableContentsInput.sort(
					(first, second) =>
						first[this.sortKey] < second[this.sortKey]
							? this.sortDir === 'ASC'
								? 1
								: -1
							: this.sortDir === 'ASC'
							? -1
							: 1
				);
			} else {
				this.tableContentsInput = this.tableContentsInput.sort(
					(first, second) =>
						first[this.sortKey]?.toString().toLowerCase() <
						second[this.sortKey]?.toString().toLowerCase()
							? this.sortDir === 'ASC'
								? 1
								: -1
							: this.sortDir === 'ASC'
							? -1
							: 1
				);
			}
		} else {
			this.tableContentsInput = this.tableContentsInput.sort(
				(first, second) =>
					new Date(first[this.sortKey]) <
					new Date(second[this.sortKey])
						? this.sortDir === 'ASC'
							? 1
							: -1
						: this.sortDir === 'ASC'
						? -1
						: 1
			);
		}
		this.tableContentsInput = JSON.parse(
			JSON.stringify(this.tableContentsInput)
		);
		this.setPageContents();
	}
}
