export enum ReportColumn {
	DASHBOARD = 'dashboard',
	USER_MONTHLY_SUMMARY = 'monthlysummary',
	USER_EDUCATION_SUMMARY = 'educationsummary',
	USER_EDUCATION_STATUS = 'userstatus',
	USER_EDUCATION_STATUS_DETAIL = 'userstatusdetail',
	USER_HISTORY_DETAIL = 'userHistoryDetail',
	SKILLS_SUMMARY = 'skillsummary',
	COURSE_COMPLETION_DETAIL = 'coursecompletiondetail',
	COURSE_REQUIRED_EDUCATION_COMPLETION = 'requirededucationcompletion',
	COURSE_REQUIRED_EDUCATION_COMPLETION_GRID = 'requirededucationcompletionGridView',
	REQUIRED_REPORT = 'requiredReport',
	USER_EDUCATION_ASSIGNED = 'educationAssignedToUser',
}
