export enum UrlNames {
	/**
	 * Help System
	 */
	helpSystemCategoires = '/categories',
	helpSystemSections = '/sections',
	helpSystemArticles = '/articles',
	searchArticle = '/search.json',
	articlesJson = '/articles.json',
	createQuestion = '/api/survey/v1/question',

	/**
	 * Unsubscribe Surge Feeds
	 */
	unsubscribe = '/api/alerts/v1/unsubscribe',

	/**
	 * Alerts
	 */
	notificationCounts = '/api/alerts/v1/count',
	notifications = '/api/alerts/v1/',

	/**
	 * Onboard Client
	 */
	checkClientCode = '/clients/check',
	addClient = '/clients/v2/client',
	onboardImportUsers = '/clients/import/users',

	/**
	 * Resource Centre
	 */
	resourceCenter = '/resourceCenter',
	resources = '/resources',
	docs = '/docs',
	showDocs = '/showdocs',
	courses = '/courses',
	documents = '/documents',
	archiveCourses = '/archive',
	getLearningPathways = '/learningpathways',
	inactivate = '/inactivate',
	reportingGroup = '/reportinggroup',
	search = '/search',
	locations = '/locations',
	activate = '/activate',
	hide = '/hide',
	recommend = '/recommendV3',
	privileges = '/surge/privileges',
	assign = '/assign',
	assignV3 = '/assignV3',
	unassignLp = '/unassign',
	unassign = '/course/unassign',
	unassignDocs = '/docs/unassign',
	unrecommend = '/course/unrecommend',
	unrecommendDocs = '/docs/unrecommend',
	unrecommendLp = '/unrecommend',
	courseTests = '/course/tests',
	addCourseTest = '/alltests',
	addLpTests = '/lptests',
	getCourseTest = '/course/test',
	upload = '/upload',
	cluster = '/cluster',
	lp = '/lp',
	unarchive = '/unarchive',
	edit = '/edit',
	courseOrLp = '/courseOrLP',
	assignments = '/assignmentsV3',
	editAssignments = '/assignments/edit',
	deleteTest = '/tests/delete',
	restore = '/restore',
	deleteUserAssignment = '/userassignments',
	categorycourses = '/categorycourses',
	hasSharedLibrary = '/shared',
	uploadNonScorm = '/upload-zip',
	processZip = '/process-zip',
	markExistingRegistrations = '/resources/courses/revoke/registrations',
	showHiddenLocations = '/showHiddenLocations',
	ViewAssignments = '/view/assignments',
	checkForAssigned = '/courses/checkForAssigned',
	courseAuditTrail = '/courseAuditTrail',
	lpAuditTrail = '/lpAuditTrail',

	/**
	 * Auth Endpoints
	 */
	forgotPassword = '/users/v2/password/forgot',
	forgotPasswordConfirmation = '/users/v2/password/forgot/confirm',
	changePassword = '/users/v2/password/change',
	changeUserPassword = '/users/v2/user/reset-password',
	changeEmail = '/users/change/email',
	oauth2 = 'oauth2/userInfo',
	getUserSites = '/users/sso/sites',
	sessionTracking = '/users/sessions/track',
	loadUserEmail = '/users/email',
	//newUserLogin = '/auth/sso/v1/signin',
	newUserLogin = '/auth/sso/v1/login',
	logoutNewUserSession = '/auth/sso/v1/logout',
	refreshSession = '/auth/sso/v1/refresh',
	fetchIdToken = '/user/token/secret',
	linkedUserLogin = '/auth/sso/v1/profile/link/login',
	getMSAuthLink = '/auth/sso/v1/ms/redirect',
	msAuth = '/auth/sso/v1/ms/login',
	decryptedToken = '/auth/sso/v1/utility/decrypt/token',

	/**
	 * Broadcast and messaging Endpoints
	 */

	getAllMessages = '/api/broadcast/v1',
	sendMessagesEncrypted = '/api/broadcast/v2',
	sendMessage = '/api/broadcast/v1/user',
	getValidEmailCount = '/api/broadcast/v1/valid_email_count',
	markMessageAsSeen = '/api/broadcast/v1/seen',
	getBroadcastList = '/api/announcement/v1',
	higherThanRole = '/higherThanRole',

	/**
	 * Manage Users Endpointsf
	 */
	users = '/users',
	usersUpdate = '/users/v2/userdetails',
	usersLogin = '/users/signin',
	newprivileges = '/users/privileges',
	userClasses = '/users/classes',
	userSupervisors = '/users/supervisors',
	manageUserSupervisors = '/users/v2/supervisors',
	userLocations = '/users/locations',
	educationGroups = '/users/educationgroups',
	userRegister = '/users/v2/register',
	userDetails = '/users/userdetails',
	userInactivationReasons = '/users/reasons',
	userRegisterOnCognito = '/users/v2/cognito/register',
	activateUsers = '/users/activate',
	inactivateUsers = '/users/inactivate',
	addEdGroup = '/users/educationgroup/add',
	changeEdGroup = '/users/educationgroup/change',
	removeEdGroup = '/users/educationgroup/remove',
	removeAssociateEdGroup = '/users/educationgroup/remove/associate',
	manager = '/users/userdetails/manager',
	primary = 'primary',
	secondary = 'secondary',
	bygroup = 'bygroup',
	userExist = '/clients/users/exists',
	bulkAssign = '/users/skills/bulk',
	editSkills = '/skills/editSpecificSkill',
	importUsers = '/users/import',
	customiseUserTable = '/users/customize/fields',
	getLocations = '/profiles/enterprise/locations',
	getUserByLocation = '/profiles/locations/users',
	linkProfiles = '/profiles/link',
	linklegacy = '/profiles/linklegacy',
	legacyLinkProfiles = '/profiles/link',
	linkedProfiles = '/profiles/linked',
	lnkedLocations = '/profiles/locations',
	changePasswordByPrivilegeUser = '/users/v2/privilege/password/change',
	showUserEducationHistory = '/users/education/history',
	showUserStatusHistory = '/users/status/history',
	changeUsername = '/users/username/change',
	removeUserInCognito = '/users/cognito/user',
	userOrientationMode = '/users/user/orientationmode',
	loadUsersOrientationGroupsData = '/users/user/orientationdata',
	emailUsername = '/users/usernameemail',
	emailtoRestPassword = '/users/user/reset-email',
	transferUserDataFromOneLocationToAnother = '/users/user/transfer',
	importUserData = '/users/user/import',
	resetToken = '/users/user/validate-reset-token',
	getClientZoneTime = '/users/v2/client/currentTime',

	/**
	 * My Courses Endpoints
	 */

	myCourses = '/mycourses',
	myCoursesv3 = '/mycoursesV3',
	v2myCourses = '/v2/mycourses',
	viewCert = '/mycourses/view/certificate/path',
	viewCertificate = '/mycourses/viewcertificate',
	downloadCertificate = '/mycourses/certificate/download',
	shareCertificate = '/mycourses/sharecertificate',
	takeCourse = '/takecourse',
	myCoursesMandatory = '/mandatory',
	myCoursesRecommend = '/recommended',
	myCoursesHistory = '/history',
	courseDetails = '/coursedetails',
	courseDetailsV3 = '/coursedetailsV3',
	sendFeedback = '/mycourses/sendfeedback',
	sendRequestSuppport = '/mycourses/sendrequestsupport',
	nonScormPresignedUrl = '/mycourses/nonscorm/presigned/url',
	sendLiveEventNames = '/mycourses/liveevent/email',
	resource = '/resource',
	resourceContent = '/addResourceContent',
	learningPathway = '/learningpathway',
	course = '/course',
	selfAssigned = '/selfassign',
	unhide = '/unhide',
	presignedUrl = '/v2/s3/presignedurl',
	takeTest = 'taketest',
	submit = '/submit',
	test = '/test',
	courseProgress = '/save/courseprogress',
	contentComplete = '/save/contentcompletionstatus',
	myLiveEvents = '/mycourses/liveevents',
	updateRochTestDetails = '/cobas',
	updateCourseRusticiFlag = '/rustici/scorm/completion',

	tests = '/tests',
	testResponse = '/mycourses/questions/answers',
	programEvalutaion = '/mycourses/common/evaluation/program',
	getTotalUsersWhoCompletedCourse = '/mycourses/course/completion/count',
	saveQuestionComment = '/mycourses/save/questioncomment',
	getCourseTestAttemptCount = '/mycourses/course/test/attempt/count',
	loadMyCourseTestQuizData = '/mycourses/quiz/questions/answers',
	loadQuizSummary = '/mycourses/quiz/summary',
	loadLpQuizSummary = '/mycourses/quiz/summary/learningpath',
	publicFileUpload = '/mycourses/upload',

	saveSCORMCourseTestData = '/rustici/interactions',

	/**
	 * Survyes Endpoints
	 */

	survey = '/api/survey/v1',
	question = '/question',
	getSurveysForSurveyLibraryAndPending = '/api/survey/v1/surveyLibraryPendingPublish',
	assignSurvey = '/api/survey/v1/assign',
	assinedSurvey = '/api/survey/v1/assigned',
	activateSurvey = '/api/survey/v1/activate',
	inActivateSurvey = '/api/survey/v1/inactivate',
	unassignSurvey = '/api/survey/v1/unassign',
	retireSurvey = '/api/survey/v1/retire',
	publishSurvey = '/api/survey/v1/publish',
	saveUserSurveyResponse = '/api/survey/v1/response',
	surveyByAssignment = '/api/survey/v1/assigned',
	addGroup = '/api/survey/v1/group',
	addMembersToGroup = '/api/survey/v1/group/member',
	getGroups = '/api/survey/v1/group',
	getGroupMembers = '/api/survey/v1/group-details',
	deleteGroup = '/api/survey/v1/group',
	deleteMember = '/api/survey/v1/group/member',
	assignSurveyToExternal = '/api/survey/v1/assign/external',
	surveyReport = '/surveyReport',
	viewAnswers = '/viewAnswers',
	surveyAssignmentDetails = '/api/survey/v1/assignment/survey',
	deleteSurveyAssignments = '/api/survey/v1/assignment/delete',
	editSurveyAssignments = '/api/survey/v1/assignment',
	sequencing = '/sequencing',
	surveyOnDemand = '/api/survey/v1/assigned/ondemand',
	getSurveyPreviewBySurveyId = '/api/survey/v1/preview',
	getSurveyCategories = '/api/survey/v1/category',
	getSurveysByCategory = '/api/survey/v1/category/survey',
	getSurveysByCategoryId = '/api/survey/v1/categoryForReport',
	deleteSurveyCategory = '/api/survey/v1/surveyCategory',
	editOverView = '/api/survey/v1/overview/details',
	importQuestions = '/api/survey/v1/section',
	overviewForPDF = '/overviewForPDF',

	/**
	 * Education Categories Endpoints
	 */
	categories = '/categories',
	category = '/category',
	categoriesAddCategory = 'addCategory',
	categoriesAddSubCategory = 'addSubCategory',
	categoriesEditCategory = 'editCategory',
	categoriesEditSubCategory = 'editSubCategory',
	categoriesDeleteCategory = 'deleteCategory',
	categoriesDeleteSubCategory = 'deleteSubCategory',

	/**
	 * Education Groups Endpoints
	 */
	locationGroups = '/locationGroups',
	groups = '/groups',
	groupsAddGroup = 'addGroup',
	groupsDeleteGroup = 'deleteGroup',
	groupsEditGroup = 'editGroup',

	/**
	 * Dashboard Endpoints
	 */
	dashboard = '/dashboard',
	pdf = 'pdf',
	dashboardGetRegularBadges = 'getRegularBadges',
	dashboardGetCustomBadges = 'getCustomBadges',
	dashboardGetAllRewards = 'getAllRewardsOnLoadInConfig',
	dashboardAddNewBadgeInConfiguration = 'addNewBadgeInConfiguration',
	dashboardEditBadgesInConfig = 'editBadgesInConfig',
	additionalLinks = 'additionallinks',
	getDetailsOnLoadOfDashBoard = 'getDetailsOnLoadOfDashBoard',
	getDetailsOnLoadBroadcast = 'api/announcement/v1/user',
	SendBroadcast = 'api/announcement/v1',
	dashboardGetTriggerTypesInBadges = 'getTriggerTypesInBadges',
	dashboardOnLoadAddNewBadge = 'onLoadAddNewBadge',
	dashboardGetAllBadgesToLink = 'getAllBadgesToLink',
	dashboardGetBadge = 'badges',
	dashboardGetReward = 'rewards',
	dashboardAddNewRewardInConfig = 'addNewRewardInConfig',
	dashboardEditRewardsInConfig = 'editRewardsInConfig',
	dashboardRedeemMyRewardsCollection = 'redeemMyRewardsCollection',
	dashboardRedeemMyRewards = 'redeemMyRewards',
	sendGratitudeBadges = 'sendGratitudeBadges',
	viewSentGratitudeBadges = 'viewSentGratitudeBadges',
	viewRewardRecord = 'viewRewardRecord',
	activateBadge = '/activateBadge',
	uploadDefaultImages = '/uploadDefaultImages',
	getMyRewards = 'getMyRewards',
	getDetailsManageRewards = 'getDetailsManageRewards',

	/**
	 * Skills & Credentials Endpoints
	 */
	skills = '/skills',
	skillsOnLoad = '/skills/onLoad',
	addNewSkill = '/skills/addNewSkill',
	deleteSkill = '/skills/deleteSkills',
	updateSkill = '/skills/editSkill',
	assignedSkill = '/skills/assignSkills',
	onLoadUsers = '/onLoadUsers',
	loadUserComments = '/loadCommentsForUser',
	addUserComment = '/addCommentToUser',
	deleteUserSkillComment = '/deleteCommentForUser',
	loadUserCertificates = '/loadCertificateForUser',
	addUserCertificate = '/addCertificateToUser',
	deleteUserSkillCertificate = '/deleteCertificateForUser',
	myProfile = '/myprofile',
	competences = '/competences',
	addUserSkill = '/addSkillToUser',
	updateSkillUsers = '/updateSkillToUser',
	loadUsersApartfromSelectedSkill = '/loadUsersApartfromSelectedSkill',
	loadUsersForSelectedSkillId = '/loadUsersForSelectedSkillId',
	deleteUserSkill = '/users/myprofile/skills',
	updateUserSkillStatus = '/updateSkillToUserFromReports',

	/**
	 * User Profile endpoints
	 */

	getProfile = '/users/myprofile/details',
	getProfileSkills = '/users/myprofile/skills',
	addCommentToSkill = '/users/myprofile/skills/comments',
	getAllSkills = `/available`,
	userSkills = '/users/skills',
	userCourseCompletionStatus = '/users/courses/completion/status',

	/**
	 * Live events
	 */
	liveEvents = '/liveevents',
	saveLiveEvent = '/liveevent',
	editLiveEvent = '/liveevent/edit',
	editSessionDetails = '/liveevent/session/edit',
	availableEvents = '/availableevents',
	allLiveEvents = '/allliveevents',
	getLiveEventSurveys = '/liveevents/surveys',
	saveLiveEventSessions = '/liveeventsession',
	saveLiveEventDetailsPrerequisite = '/liveevents/linkeducation',
	saveRepeatSessions = '/repeatsessions',
	upcomingassignevets = '/liveevents/upcomingassignevets',
	upcomingopenevets = '/liveevents/upcomingopenevets',
	getSessionPopupDetails = '/liveevents/popup/details',
	getSessionPopupAttendes = '/liveevents/popup/attendees',
	getManageLiveEvents = '/liveevents/manage',
	getManageLiveEventsforLp = '/liveevents/lp/manage',
	getManageLiveEventSessions = '/liveevents/manage/sessions',
	getSessionDetails = '/liveevents/session/details',
	deleteLiveEvent = '/liveevents/delete',
	saveUnRegisterUser = '/liveevents/unRegisterUser',
	addRegistration = '/liveevents/reguser',
	getFirstActiveSessionDetails = '/liveevents/firstsession',
	addAttendance = '/liveevents/attendance',
	activateLiveEvent = '/liveevents/activate',
	inactivateLiveEvent = '/liveevents/inactivate',
	liveEventsCaps = '/liveEvents',
	liveEventPdfDownload = '/liveevents/pdf/download',

	/*Reports */
	userReports = '/userReports',
	userDashboardReport = '/userDashboardReportSearchV3',
	courseReports = '/userCourseReportsSearch',
	otherReports = '/otherReports',
	skillReport = '/skillsReport',
	skillSummaryReport = '/onLoadskillSumaryReport',
	skillsComingDue = '/fetchSkillsComingDue',
	skillPendingApproval = '/fetchSkillPendingApproval',
	getCourseReports = '/courseReports',
	utilizationReport = '/getCourseUtilizationReport',
	monthlySummaryReport = '/getUserMonthlySummaryReport',
	reportMetrics = '/reportsMetrics',
	summaryReport = '/onloadReportsV3',
	userEducationStatus = '/onLoadUserEducationStatusV3',
	surveySummaryReport = '/fetchSurveySummaryReport',
	individualSurveyReport = '/fetchSurveyIndividualReport',
	userEducationHistoryReport = '/onLoadUserEdHistoryDetail',
	courseCompletion = '/onLoadcourseCompletion',
	educationSummaryReport = '/getUserEducationSummaryReport',
	courseReport = '/courseReport',
	courseDetailReport = '/courseDetailReport',
	courseDetailAggregate = '/courseDetailaggregate',
	getRequiredGridCourses = '/getAllCoursesForRequiredEducation',
	postRequiredGridCourses = '/getAllCoursesForRequiredEducationGridView',
	gridView = '/gridView',
	requiredEducation = '/courseRequiredEducation',
	postRequiredEducation = '/fetchCourseRequiredEducationGridView',
	tableView = '/tableView',
	requiredCourses = '/courseRequiredEducationTable',
	requiredCoursesPost = '/courseRequiredEducationTableView',
	requiredAggregate = '/getAggregateForRequiredEducation',
	requiredEducationReport = '/requiredEducationReportV3',
	userAssignments = '/requiredEducationAssignedToUser',
	requiredEducationHistory = '/fetchRequiredEducationHistory',
	userEducationSummaryAggregate = '/getAggregateUserEdSummaryReport',
	testSummaryReport = '/fetchTestSummaryReport',
	courseReportsUrl = '/courseReports',
	programEvaluation = '/programEvaluation',
	programEvaluationDetail = '/programEvaluationDetail',
	loginHistory = '/loginHistory',
	userEducationStatusColumnSave = '/reports/userstatus/customize/fields',
	userEducationStatusDetailColumnSave = '/reports/userstatusDetail/customize/fields',
	userMonthlySummaryColumnSave = '/reports/monthlySummary/customize/fields',
	userEducationHistoryDetailColumnSave = '/reports/userhistoryDetail/customize/fields',
	courseRequiredReportColumnSave = '/reports/requiredReport/customize/fields',
	courseRequiredEdCompletionColumnSave = '/reports/requiredEducationCompletion/customize/fields',
	courseRequiredEdCompletionColumnSaveGrid = '/reports/requiredEducationCompletionGridView/customize/fields',
	userDashboardColumnSave = '/reports/dashboard/customize/fields',
	userEducationSummaryColumnSave = '/reports/educationSummary/customize/fields',
	courseCompletionDetailColumnSave = '/reports/courseCompletionDetail/customize/fields',
	educationAssignedToUserColumnSave = '/reports/educationAssignedToUser/customize/fields',
	skillsSummaryColumnSave = '/reports/skillsSummary/customize/fields',

	customiseColumsUserStatusDetail = '/customize/fields/userstatusdetail',
	customiseColumsMonthlySummary = '/customize/fields/monthlySummary',
	customiseColumsEducationSummary = '/customize/fields/educationSummary',
	customiseColumsUserHistoryDetails = '/customize/fields/userHistoryDetail',
	customiseColumsRequiredReport = '/customize/fields/requiredReport',
	customiseColumsRequiredEdCompletion = '/customize/fields/requiredEdCompletion',
	customiseColumsRequiredEdCompletionGridPost	= '/reports/requiredEducationCompletionGridView/customize/fields',
	customiseColumsRequiredEdCompletionGridGet = '/customize/fields/requiredEdCompletionGridView',
	customiseColumnsUserDashboard = '/customize/fields/dashboard',
	customiseColumnsCourseCompletionDetails = '/customize/fields/ccDetail',
	customiseColumsEducationAssignedToUser = '/customize/fields/educationAssignedUser',
	customiseColumsEducationUserStatusHistory = '/customize/fields/userStatus',
	customiseColumsSkillsSummary = '/customize/fields/skillsSummary',
	/**
	 * User Settings
	 */
	manageCodes = '/managecodes',
	codeDetails = '/codedetails',
	grandperentList = '/grandparentlist',
	parentList = '/parentlist',
	locationList = '/locationlist',
	sharedList = '/sharedlist',
	codelist = '/codelist',
	deleteManageCodes = '/managecodes/delete',
	getSettings = '/settings/location/settings',
	getUISettings = '/settings/location/settings/ui',
	getUserAccessSettings = '/settings/location/useraccess',
	getProductAccessSettings = '/settings/v2/products/access',
	getGeneralUserProperties = '/product/settings/v1/properties',
	getAccountManagers = '/settings/products/access/account/managers',
	userClassAccess = '/users/accessorder',
	editdocsharer = '/managecodes/editdocsharer',
	/**
	 * QRM
	 */

	qrmCategories = '/api/qrm/v1/category',
	qrmAudit = '/api/qrm/v1/qrmaudit',
	qrmSchedule = '/api/qrm/v1/schedule',
	qrmRoles = '/api/qrm/v1/role',
	myQrm = '/api/qrm/v1/myqrm',
	getQrmResponse = '/api/qrm/v1/qrmresponse/qrmAssignment',
	getQrmResponseview = '/api/qrm/v1/qrmaudit',
	captureAuditResponse = '/api/qrm/v1/captureresponse',
	getAllSchedules = '/api/qrm/v1/schedule/audit',
	getAllQrm = '/api/qrm/v1/audits',

	/**
	 * SCORM
	 */

	uploadScormCourse = 'courses/importJobs/upload/',
	checkUploadStatus = 'courses/importJobs/',
	scormRegistrations = 'registrations',
	launchLink = 'launchLink',
	courseIdentifier = '/courses/identifier',
	assignmentIdentifier = '/courses/registrations/identifier',
	getTotalUserCount = '/about/userCount',

	/**
	 *
	 * System Tools
	 */

	systemTools = '/systemtools',
	searchClientCode = '/sites',
	searchUser = '/users',
	emailQueue = '/api/alerts/v1/queue',
	resetattempts = '/resetattempts',
	getActiveClientList = '/clients',

	/**
	 * Vimeo
	 */

	vimeoAuth = '/oauth/authorize/client',
	tusUpload = '/me/videos',

	/**
	 * Security Questions.
	 */

	loadUserSavedSecurityQuestions = '/security/users/saved/questions',
	loadAvailableSecurityQuestions = '/security/questions/available',
	saveUsersSecurityQuestions = '/security/questions',
	isUserFirstTimeLogin = '/security/users/firstlogin',
	isUserHavingSecurityQuestionsOrNot = '/security/users/questions',
	checkUsersSecurityQuestionsAnswers = '/security/questions/users/answers',
	changePasswordAfterSecurityQuestion = '/users/v2/security/privilege/password/change',
	getSecretKey = '/users/data/secret',
}
