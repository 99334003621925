import { Injectable } from '@angular/core';
import {
	BehaviorSubject,
	forkJoin,
	interval,
	map,
	mergeMap,
	Observable,
	of,
	switchMap,
	take,
	takeWhile,
	tap,
} from 'rxjs';
import { BaseWebApiService } from 'src/app/core/services/_base-web-api.service';
import { UrlNames } from 'src/app/core/services/urlProfiler';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { ArchivePayload } from 'src/app/shared/models/archive-course-list';
import { LearningPathwaysPayload } from 'src/app/shared/models/learning-pathways.model';
import {
	categoryListParams,
	ChangeCoursesStatusPayload,
	RegisterScormCoursePayloadInterface,
	UnhideResourcePayload,
} from 'src/app/shared/models/categories';
import { RecommendCoursesPayload } from 'src/app/shared/models/recommend-courses.model';
import { AssignPayload } from 'src/app/shared/models/assign-courses';
import { LocationList } from 'src/app/shared/models/locations';
import { FileLocationDetails } from 'src/app/core/models/file-upload.interface';
import { SearchCourseDetails } from 'src/app/shared/models/create-resource';
import { EditAssignmentPayload } from '../_models/assignments.model';
import { environment } from 'src/environments/environment';
import { EncryptionService } from 'src/app/core/services/encryption.service';
import { CourseAssignmentDataInterface } from '../_models/course-assignment-data.interface';
import { UserReportsService } from '../../reports/_services/user-reports.service';

@Injectable({
	providedIn: 'root',
})
export class ResourceCenterService {
	public courseId: number;
	public registrationId: number;
	public basicCourseDetails: { name: string; description: string };

	public scormUploadError = new BehaviorSubject<boolean>(false);
	public scormCourseUploading = new BehaviorSubject<boolean>(false);
	constructor(
		private apiService: BaseWebApiService,
		private localData: LocalDataService,
		private encryptionService: EncryptionService,
		private userReportService: UserReportsService
	) {}
	public getResourceCategories(params: categoryListParams): Observable<any> {
		return this.apiService.get(UrlNames.courses, params);
	}

	public getCategories(params: categoryListParams): Observable<any> {
		return this.apiService.get(UrlNames.categorycourses, params);
	}

	public getDocsCategories(params: any): Observable<any> {
		return this.apiService.get(
			`${UrlNames.resources}${UrlNames.docs}${UrlNames.categories}`,
			params
		);
	}
	public getDocCategoryDetails(params: categoryListParams): Observable<any> {
		return this.apiService.get(
			`${UrlNames.resources}${UrlNames.docs}${UrlNames.showDocs}`,
			params
		);
	}

	public getDocsDetail(docId: number[]): Observable<any> {
		return this.apiService.get(`${UrlNames.resources}${UrlNames.docs}`, {
			docIds: docId,
			locationId: this.localData.getLocationId(),
		});
	}

	public getLearningPathways(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.courses}${UrlNames.getLearningPathways}`,
			{ locationId: this.localData.getLocationId() }
		);
	}

	public postLearningPathways(
		learningPathwayPayload: LearningPathwaysPayload
	): Observable<any> {
		return this.apiService.post(
			`${UrlNames.courses}${UrlNames.getLearningPathways}`,
			learningPathwayPayload
		);
	}

	public postLearningPathwaysDocs(
		learningPathwayPayload: LearningPathwaysPayload
	): Observable<any> {
		return this.apiService.post(
			`${UrlNames.documents}${UrlNames.getLearningPathways}`,
			{
				...learningPathwayPayload,
				locationId: this.localData.getLocationId(),
				userId: this.localData.getUserId(),
			}
		);
	}

	public archiveCourseResponseData(
		archiveCourseResponseData: ArchivePayload
	): Observable<any> {
		return this.apiService.put(
			`${UrlNames.courses}${UrlNames.archiveCourses}`,
			{ ...archiveCourseResponseData, ...this.getLoggedInUserDetails() }
		);
	}

	public archiveDocsResponseData(
		archiveCourseResponseData: ArchivePayload
	): Observable<any> {
		return this.apiService.put(
			`${UrlNames.docs}${UrlNames.archiveCourses}`,
			{ ...archiveCourseResponseData, ...this.getLoggedInUserDetails() }
		);
	}

	public inactivateCourses(
		body: ChangeCoursesStatusPayload
	): Observable<any> {
		return this.apiService.put(
			`${UrlNames.courses}${UrlNames.inactivate}`,
			{
				...body,
				...this.getLoggedInUserDetails(),
			}
		);
	}

	public inactivateDocs(docIds: Array<Number>): Observable<any> {
		return this.apiService.put(`${UrlNames.docs}${UrlNames.inactivate}`, {
			docs: docIds,
			...this.getLoggedInUserDetails(),
		});
	}

	public activateCourses(body: ChangeCoursesStatusPayload): Observable<any> {
		return this.apiService.put(`${UrlNames.courses}${UrlNames.activate}`, {
			...body,
			...this.getLoggedInUserDetails(),
		});
	}

	public activateDocs(body: ChangeCoursesStatusPayload): Observable<any> {
		return this.apiService.put(`${UrlNames.docs}${UrlNames.activate}`, {
			...body,
			...this.getLoggedInUserDetails(),
		});
	}

	public hideCourseResponseData(
		hideCourseResponseData: ChangeCoursesStatusPayload
	): Observable<any> {
		return this.apiService.put(`${UrlNames.courses}${UrlNames.hide}`, {
			...hideCourseResponseData,
			...this.getLoggedInUserDetails(),
		});
	}
	public hideDocuments(
		hideCourseResponseData: ChangeCoursesStatusPayload
	): Observable<any> {
		return this.apiService.put(
			`${UrlNames.docs}${UrlNames.hide}`,
			hideCourseResponseData
		);
	}
	public unhideCourses(
		unhidePayload: UnhideResourcePayload
	): Observable<any> {
		return this.apiService.put(`${UrlNames.courses}${UrlNames.unhide}`, {
			...unhidePayload,
			userId: this.localData.getUserId(),
			locationId: this.localData.getLocationId(),
		});
	}
	public unhideDocuments(
		unhidePayload: UnhideResourcePayload
	): Observable<any> {
		return this.apiService.put(
			`${UrlNames.docs}${UrlNames.unhide}`,
			unhidePayload
		);
	}

	public deleteCourses(body: ChangeCoursesStatusPayload): Observable<any> {
		return this.apiService.delete(`${UrlNames.courses}`, {
			...body,
			userId: this.localData.getUserId(),
			locationId: this.localData.getLocationId(),
		});
	}
	public deleteDocs(body: ChangeCoursesStatusPayload): Observable<any> {
		return this.apiService.delete(`${UrlNames.docs}`, {
			...body,
			userId: this.localData.getUserId(),
			locationId: this.localData.getLocationId(),
		});
	}
	public getReportingGroups(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.courses}${UrlNames.reportingGroup}`,
			{
				userId: this.localData.getUserId(),
				clientCode: this.localData.getSiteCode(),
			}
		);
	}
	public getExcludedCourseList(
		langId: number
	): Observable<Array<SearchCourseDetails>> {
		const courseIds = this.localData.selectedCourses.map(
			(course) => course.id
		);
		return this.apiService.get(`${UrlNames.courses}${UrlNames.search}`, {
			language: langId,
			except: courseIds.join(','),
			userid: this.localData.getUserId(),
			locationId: this.localData.getLocationId(),
		});
	}
	public getExcludedDocsList(
		langId: number,
		docIds: any
	): Observable<Array<SearchCourseDetails>> {
		return this.apiService.get(`${UrlNames.documents}${UrlNames.search}`, {
			language: langId,
			except: docIds.join(','),
			userid: this.localData.getUserId(),
			locationId: this.localData.getLocationId(),
		});
	}
	public getLocationsList(
		locationId: number,
		isLiveEvents: boolean = false
	): Observable<LocationList> {
		return this.apiService
			.get(`${UrlNames.locations}`, {
				userId: this.localData.getUserId(),
				isLiveEvents: isLiveEvents,
				locationId,
			})
			.pipe(
				map((x: LocationList) => {
					return {
						...x,
						userList: this.localData.sortStrings(
							x.userList,
							'firstName'
						),
					};
				})
			);
	}
	public createReportingGroup(name: string) {
		return this.apiService.post(
			`${UrlNames.courses}${UrlNames.reportingGroup}`,
			{},
			{
				name: name,
				locationId: this.localData.getLocationId(),
				clientCode: this.localData.getSiteCode(),
			}
		);
	}

	public recommendCourses(
		recommendCoursesPayload: RecommendCoursesPayload,
		isLp: boolean = false
	): Observable<any> {
		return this.apiService.post(
			isLp
				? `${UrlNames.lp}${UrlNames.recommend}`
				: `${UrlNames.courses}${UrlNames.recommend}`,
			recommendCoursesPayload
		);
	}
	public assignCourses(body: AssignPayload) {
		return this.apiService.post(
			`${UrlNames.courses}${UrlNames.assign}`,
			body
		);
	}
	public assignDocs(body: AssignPayload | RecommendCoursesPayload) {
		return this.apiService.post(
			`${UrlNames.documents}${UrlNames.recommend}`,
			body
		);
	}
	public getPresignedUrl(path: string): Observable<FileLocationDetails> {
		return this.apiService.get(`${UrlNames.getSecretKey}`, {}).pipe(
			mergeMap((response: any) => {
				let key = response?.secretKey;
				return this.apiService
					.post(`${UrlNames.v2myCourses}${UrlNames.takeCourse}`, {
						path: this.encryptionService.encrypt(path, key),
						duration: this.encryptionService.encrypt('', key),
					})
					.pipe(map((res: FileLocationDetails) => res));
			})
		);
	}

	public getAllCategories() {
		return this.apiService.get(
			`${UrlNames.courses}${UrlNames.categories}`,
			{
				userId: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
			}
		);
	}
	public unassignCourses(
		payload: ChangeCoursesStatusPayload
	): Observable<Object> {
		return this.apiService.post<Object>(`${UrlNames.unassign}`, {
			...payload,
			...this.getLocationId(),
			...this.getUserId(),
		});
	}
	public unassignDocs(
		payload: ChangeCoursesStatusPayload
	): Observable<Object> {
		return this.apiService.post<Object>(`${UrlNames.unassignDocs}`, {
			...payload,
			...this.getLocationId(),
			...this.getUserId(),
		});
	}
	public unRecommendCourses(
		payload: ChangeCoursesStatusPayload
	): Observable<Object> {
		return this.apiService.post<Object>(`${UrlNames.unrecommend}`, {
			...payload,
			...this.getLocationId(),
			...this.getUserId(),
		});
	}
	public unRecommendDocuments(
		payload: ChangeCoursesStatusPayload
	): Observable<Object> {
		return this.apiService.post<Object>(`${UrlNames.unrecommendDocs}`, {
			...payload,
			...this.getLocationId(),
			...this.getUserId(),
		});
	}
	public getCourseDetails(courseId: number): Observable<Object> {
		return this.apiService.get(`${UrlNames.course}/${courseId}`, {});
	}
	public getLpDetails(lpId: number): Observable<any> {
		return this.apiService.get(`${UrlNames.learningPathway}/${lpId}`, {});
	}
	public unarchiveCourses(payload: {
		courses: Array<number>;
		learningPathwayIdList: Array<number>;
	}): Observable<any> {
		return this.apiService.put(`${UrlNames.courses}${UrlNames.unarchive}`, {
			...payload,
			...this.getLoggedInUserDetails(),
		});
	}
	public unarchiveDocs(payload: { docs: Array<number> }): Observable<any> {
		return this.apiService.put(`${UrlNames.docs}${UrlNames.unarchive}`, {
			...payload,
			...this.getLoggedInUserDetails(),
		});
	}
	/**
	 * To get an identifier number from backend which will be new course's Id.
	 */
	public getCourseId(): void {
		this.apiService
			.get<{ courseId: number }>(`${UrlNames.courseIdentifier}`, null)
			.pipe(take(1))
			.subscribe({
				next: (res) => {
					this.courseId = res.courseId;
				},
			});
	}
	/**
	 * To get an identifier number from backend which will be new assignment's Id.
	 */
	public getAssignmentId(): Observable<number> {
		return this.apiService
			.get<{ registrationId: number }>(
				`${UrlNames.assignmentIdentifier}`,
				null
			)
			.pipe(
				take(1),
				tap((x) => (this.registrationId = x.registrationId)),
				map((x) => x.registrationId)
			);
	}

	/**
	 *
	 * @param courseId
	 * @returns Observable if the course is already assigned or not?
	 */
	public checkIfCourseIsAssigned(
		courseId: number
	): Observable<{ alreadyExist: boolean }> {
		return this.apiService.get(
			`${UrlNames.courses}${UrlNames.selfAssigned}`,
			{ courseId: courseId, userId: this.localData.getUserId() }
		);
	}

	public checkScormUploadStatus(jobId: string): Observable<any> {
		return interval(1000).pipe(
			switchMap(() =>
				this.apiService.get(
					`${UrlNames.checkUploadStatus}${jobId}`,
					null,
					'scorm'
				)
			),
			takeWhile((res) => res.status === 'RUNNING', true)
		);
	}

	public uploadScormCourse(
		file: File,
		courseId: number = this.courseId
	): Observable<any> {
		const formData = new FormData();
		formData.append('file', file);
		return this.apiService.uploadFile(
			`${UrlNames.uploadScormCourse}`,
			formData,
			{
				courseId,
				mayCreateNewVersion: true,
			},
			true
		);
	}

	public uploadScormLink(link: string, duration: string): Observable<any> {
		const linkUrl = new URL(link).hostname;
		let type: string;
		if (linkUrl.includes('youtube')) {
			type = 'youtube';
		} else {
			switch (link.split('.')[link.split('.').length - 1]) {
				case 'mp4':
					type = 'video/mp4';
					break;
				case 'mp3':
					type = 'audio/mp3';
					break;
			}
		}
		const payload = {
			mediaFileReferenceRequest: {
				url: link,
				contentType: type,
				mediaFileMetadata: {
					title: this.basicCourseDetails.name,
					description: this.basicCourseDetails.description,
					contentLanguage: 'en-US',
				},
			},
		};
		return this.apiService.post(
			`courses`,
			payload,
			{
				courseId: this.courseId,
				mayCreateNewVersion: true,
			},
			'scorm'
		);
	}

	private _registerScromCourse(
		payload: RegisterScormCoursePayloadInterface
	): Observable<any> {
		return this.apiService.post(
			`${UrlNames.scormRegistrations}`,
			environment.scorm.registrationPrefix
				? {
						...payload,
						registrationId: `${environment.scorm.registrationPrefix}${payload.registrationId}`,
				  }
				: payload,
			null,
			'scorm',
			undefined,
			undefined,
			0
		);
	}

	public registerScormCourse(
		cid: number,
		assignmentId: number,
		learner: {
			id: string;
			firstName: string;
			lastName: string;
		}
	): Observable<any> {
		return this.getAssignmentId().pipe(
			take(1),
			mergeMap((id) => {
				return this.updateRegistrationInfoInDB([
					{
						courseId: cid,
						locationId: this.getLocationId().locationId,
						registrationId: id,
						userAssignmentId: assignmentId,
						userId: +learner.id,
					},
				]).pipe(
					mergeMap((res) => {
						return this._registerScromCourse({
							courseId: cid,
							learner: {
								...learner,
								id: `${res['data'][0]?.userId}`,
							},
							registrationId: id,
						});
					})
				);
			})
		);
	}

	public updateRegistrationInfoInDB(
		payload: Array<{
			courseId: number;
			locationId: number;
			registrationId: number;
			userId: number;
			userAssignmentId: number;
		}>
	): Observable<any> {
		return this.apiService.post(`/courses/registrations`, payload);
	}

	/**
	 * Unassign Scorm Course
	 * @param regId Scorm registration id
	 */
	public unassignScormCourse(regId: number[]): Observable<any> {
		return this.getRegistrationIds(regId).pipe(
			map((res) => res.registrationIds),
			map((ids) => {
				return {
					ids: ids,
					req: ids.map((x) => this._unassignScormCourse(x)),
				};
			}),
			mergeMap((req) => {
				return forkJoin(req.req).pipe(
					map((res) => {
						console.log('rus res', res);
						return { ids: req.ids, res: res };
					})
				);
			}),
			mergeMap((x) => {
				console.log('this is result', x);
				return this.deleteRegistrationFromDB(x.ids);
			})
		);
	}

	private _unassignScormCourse(regId: number): Observable<any> {
		return this.apiService.delete(
			`${UrlNames.scormRegistrations}/${regId}`,
			null,
			false,
			[],
			null,
			true
		);
	}

	private deleteRegistrationFromDB(ids: number[]): Observable<any> {
		const paylod = ids.join(',');
		return this.apiService.delete(
			`${UrlNames.courses}/${UrlNames.scormRegistrations}`,
			null,
			false,
			null,
			{ registrationIds: paylod }
		);
	}

	/**
	 * Get List of Reg Id from BE
	 * @param courseIds: number array
	 */
	private getRegistrationIds(
		courseIds: number[]
	): Observable<{ registrationIds: number[] }> {
		const payload = courseIds.join(',');
		return this.apiService.get(
			`${UrlNames.courses}/${UrlNames.scormRegistrations}`,
			{ courseIds: payload }
		);
	}

	private getLocationId() {
		return { locationId: this.localData.getLocationId() };
	}

	private getUserId() {
		return { userId: this.localData.getUserId() };
	}
	public getCourseAssignments(
		courseOrLPId: number | string,
		isLearningPathWay: number | string,
		year: number
	): Observable<any> {
		return this.apiService.get(`${UrlNames.assignments}`, {
			locationId: this.localData.getLocationId(),
			courseOrLPId: courseOrLPId,
			isLearningPathWay: isLearningPathWay,
			year: year ?? this.userReportService.currentEducationYear,
		});
	}

	public editCourseAssignments(
		editAssignmentPayload: EditAssignmentPayload
	): Observable<any> {
		return this.apiService.put(
			`${UrlNames.editAssignments}`,
			editAssignmentPayload
		);
	}
	private getLoggedInUserDetails(): { userId: number; locationId: number } {
		return {
			userId: this.localData.getUserId(),
			locationId: this.localData.getLocationId(),
		};
	}
	public deleteAssignmentInstances(instances: any): Observable<any> {
		return this.apiService.delete(
			`${UrlNames.assignments}`,
			instances,
			false,
			[],
			{
				userid: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
			}
		);
	}

	public deleteUserAssignment(
		courseId: number,
		learningPathId: number,
		instanceNumber: number,
		selectedUser: number
	): Observable<any> {
		return this.apiService.delete(
			`${UrlNames.deleteUserAssignment}`,
			null,
			false,
			[],
			{
				courseId,
				learningPathId,
				instanceNumber: instanceNumber,
				selectedUser: selectedUser,
				loggedinUser: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
			}
		);
	}

	public hasSharedLibrary(): Observable<any> {
		return this.apiService
			.get(`${UrlNames.hasSharedLibrary}`, {
				clientCode: this.localData.getSiteCode(),
			})
			.pipe(
				map((res) => {
					return res['hasSharedLibrary'];
				})
			);
	}

	public uploadNonScormCourse(file: File): Observable<any> {
		const formData = new FormData();
		formData.append('zipFile', file);
		return this.apiService.uploadFile(UrlNames.uploadNonScorm, formData);
	}
	public processNonScormZIP(key: string) {
		return this.apiService.post(`${UrlNames.processZip}`, null, {
			zipfilePath: key,
		});
	}

	public markExistingRegistrations(courseId: number) {
		return this.apiService.put(`${UrlNames.markExistingRegistrations}`, {
			courseId,
		});
	}

	public checkForAssigned(
		courseIds: number[],
		lpIds: number[]
	): Observable<CourseAssignmentDataInterface[]> {
		return this.apiService
			.get(`${UrlNames.checkForAssigned}`, {
				coursesList: courseIds.length ? courseIds.join(',') : '0',
				locationId: this.localData.getLocationId(),
				lpList: lpIds.length ? lpIds.join(',') : '0',
			})
			.pipe(map((res: any) => res.data));
	}
}
