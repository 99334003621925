import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { UrlNames } from 'src/app/core/services/urlProfiler';
import { BaseWebApiService } from 'src/app/core/services/_base-web-api.service';
import { CustomizeColumns } from '../_models/customize-columns.models';

@Injectable({
	providedIn: 'root',
})
export class MasterDatasService {
	constructor(
		private localData: LocalDataService,
		private apiService: BaseWebApiService
	) {}
	public getEducationGroups(): Observable<any> {
		return this.apiService.get(`${UrlNames.educationGroups}`, {
			locationId: this.localData.getLocationId(),
			type: 'LMS', //changing All to LMS to get LMS groups in Reports as per BackEnd
			userId: this.localData.getUserId(),
		});
	}
	public getSupervisors(): Observable<any> {
		return this.apiService.get(`${UrlNames.userSupervisors}`, {
			locationId: this.localData.getLocationId(),
		});
	}
	public getReportingGroups(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.courses}${UrlNames.reportingGroup}`,
			{
				userId: this.localData.getUserId(),
				clientCode: this.localData.getSiteCode(),
			}
		);
	}
}
