import { Injectable } from '@angular/core';
import { Observable, filter, map, take, tap } from 'rxjs';
import { ReportColumn } from 'src/app/core/models/report-column.enum';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { UrlNames } from 'src/app/core/services/urlProfiler';
import { BaseWebApiService } from 'src/app/core/services/_base-web-api.service';
import { DefaultReportParams } from '../report.params';
import { EduactionStatusParams } from '../_models/education-detail-report.model';
import { UserSummaryReportParams } from '../_models/education-summary-report.model';
import { UserStatusParams } from '../_models/educational-status-report.model';
import { MonthlySummaryReportParams } from '../_models/monthly-education-report.model';
import { DashboardQueryParams } from '../_models/user-dashboard-report.model';
import { UserEducationAssignmentParams } from '../_models/user-education-assignment.model';
import { UserEducationHistoryParams } from '../_models/user-history-report.model';
import { Store, select } from '@ngrx/store';
import {
	SharedState,
	getSettingValueById,
} from 'src/app/shared/_state/shared.reducer';
import { SettingsMap } from '../../user-settings/_models/settins-map.enum';
import { DatePipe } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class UserReportsService {
	public autocheckPreviousYear: boolean = false;
	private educationYearData: { date: number; month: number } = {
		date: parseInt(this.datePipe.transform(new Date(), 'yyyy')),
		month: new Date().getMonth() + 1 
	  };;
	constructor(
		private apiService: BaseWebApiService,
		private localData: LocalDataService,
		private sharedStore: Store<SharedState>,
		private datePipe: DatePipe
	) {
		this.markPreviousYearCheckbox();
		this.getRequiredDates();
	}

	public getUserDashboardReport(
		params: DashboardQueryParams
	): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}${UrlNames.userReports}${UrlNames.userDashboardReport}`,
			params
		);
	}
	public getUsersHistoryReport(params: UserStatusParams): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}${UrlNames.userReports}${UrlNames.courseReports}`,
			{ ...params, userId: this.localData.getUserId() }
		);
	}
	public getMonthlySummaryReport(
		params: MonthlySummaryReportParams
	): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}${UrlNames.userReports}${UrlNames.monthlySummaryReport}`,
			params
		);
	}
	public getReportSummary(groups: string): Observable<any> {
		return this.apiService.get(
			`${UrlNames.reportMetrics}${UrlNames.summaryReport}`,
			{
				locationId: this.localData.getLocationId(),
				userId: this.localData.getUserId(),
				groups,
				includeSettings: +this.autocheckPreviousYear,
				forOnlyEDMetrics : false
			}
		);
	}
	public getReportSummaryforOnlyEDMetricsFalse(groups: string): Observable<any> {
		return this.apiService.get(
			`${UrlNames.reportMetrics}${UrlNames.summaryReport}`,
			{
				locationId: this.localData.getLocationId(),
				userId: this.localData.getUserId(),
				groups,
				includeSettings: +this.autocheckPreviousYear,
				forOnlyEDMetrics : true
			}
		);
	}
	public getUserEducationDetails(
		params: EduactionStatusParams
	): Observable<any> {
		return this.apiService
			.get(
				`${UrlNames.dashboard}${UrlNames.userReports}${UrlNames.userEducationStatus}`,
				params
			)
			.pipe(
				tap((res) => {
					console.log('Res: ', res);
				})
			);
	}

	public getUserEducationHistoryDetails(
		params: UserEducationHistoryParams
	): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}${UrlNames.userReports}${UrlNames.userEducationHistoryReport}`,
			params
		);
	}
	public getUserEducationSummary(
		params: UserSummaryReportParams
	): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}${UrlNames.userReports}${UrlNames.educationSummaryReport}`,
			{ ...params, includeSettings: +this.autocheckPreviousYear }
		);
	}
	public getUserEducationAssignment(
		params: UserEducationAssignmentParams
	): Observable<any> {
		return this.apiService.get(
			`${UrlNames.courseReport}${UrlNames.userAssignments}`,
			params
		);
	}
	public getUserEcuationSummaryAggregate(
		params: UserSummaryReportParams
	): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}${UrlNames.userReports}${UrlNames.userEducationSummaryAggregate}`,
			params
		);
	}
	public getLoginHistory(params: any): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}${UrlNames.userReports}${UrlNames.loginHistory}`,
			params
		);
	}

	public saveUserEducationStatusColumns(payload: any) {
		return this.apiService.post(
			`${
				UrlNames.userEducationStatusColumnSave
			}/${this.localData.getUserId()}`,
			payload,
			{
				...new DefaultReportParams(
					this.localData
				).getCustomizeColumnParams(),
				report: ReportColumn.USER_EDUCATION_STATUS,
			}
		);
	}

	public saveUserEducationStatusDetailColumns(payload: any) {
		return this.apiService.post(
			`${
				UrlNames.userEducationStatusDetailColumnSave
			}/${this.localData.getUserId()}`,
			payload,
			{
				...new DefaultReportParams(
					this.localData
				).getCustomizeColumnParams(),
				report: ReportColumn.USER_EDUCATION_STATUS_DETAIL,
			}
		);
	}

	public saveUserMonthlySummaryColumns(payload: any) {
		return this.apiService.post(
			`${
				UrlNames.userMonthlySummaryColumnSave
			}/${this.localData.getUserId()}`,
			payload,
			{
				...new DefaultReportParams(
					this.localData
				).getCustomizeColumnParams(),
				report: ReportColumn.USER_MONTHLY_SUMMARY,
			}
		);
	}

	public saveUserEducationColumns(payload: any) {
		return this.apiService.post(
			`${
				UrlNames.userEducationHistoryDetailColumnSave
			}/${this.localData.getUserId()}`,
			payload,
			{
				...new DefaultReportParams(
					this.localData
				).getCustomizeColumnParams(),
				report: ReportColumn.USER_HISTORY_DETAIL,
			}
		);
	}

	public saveUserDashboardColumns(payload: any) {
		return this.apiService.post(
			`${UrlNames.userDashboardColumnSave}/${this.localData.getUserId()}`,
			payload,
			{
				...new DefaultReportParams(
					this.localData
				).getCustomizeColumnParams(),
				report: ReportColumn.DASHBOARD,
			}
		);
	}

	public saveEducationSummaryColumns(payload: any) {
		return this.apiService.post(
			`${
				UrlNames.userEducationSummaryColumnSave
			}/${this.localData.getUserId()}`,
			payload,
			{
				...new DefaultReportParams(
					this.localData
				).getCustomizeColumnParams(),
				report: ReportColumn.USER_EDUCATION_SUMMARY,
			}
		);
	}

	public saveEducationAssignedToUserColumns(payload: any) {
		return this.apiService.post(
			`${
				UrlNames.educationAssignedToUserColumnSave
			}/${this.localData.getUserId()}`,
			payload,
			{
				...new DefaultReportParams(
					this.localData
				).getCustomizeColumnParams(),
				report: ReportColumn.USER_EDUCATION_ASSIGNED,
			}
		);
	}
	public markPreviousYearCheckbox(): void {
		this.sharedStore
			.pipe(
				select(
					getSettingValueById(
						SettingsMap.my_courses_check_previous_year
					)
				),
				filter((value) => !!value),
				take(1),
				map(([checkPreviousCourses]) => {
					this.autocheckPreviousYear = checkPreviousCourses
						? true
						: false;
				})
			)
			.subscribe();
	}
	public getRequiredDates(educationalYear?: number): void {
		this.sharedStore
			.pipe(
				select(getSettingValueById(SettingsMap.client_education_year)),
				filter((value) => !!value),
				take(1)
			)
			.subscribe({
				next: ([value]) => {
					const [month, date] = value.split('-');
					this.educationYearData = {
						date,
						month,
					};
				},
			});
	}
	public get currentEducationYear(): number {
		const today = this.localData.getToday();
		const year = today.getFullYear();
		const { startDate } = this.getEducationYearDates(year);
		return startDate > today ? year - 1 : year;
	}
	public getEducationYearDates(educationYear: number): {
		startDate: Date;
		endDate: Date;
	} {
		const { date, month } = this.educationYearData;
		const startDate = new Date(educationYear, month - 1, date);
		const endDate = new Date(educationYear, month - 1, date);
		const oneDayInMillis = 24 * 60 * 60 * 1000;
		endDate.setFullYear(endDate.getFullYear() + 1);
		return {
			startDate,
			endDate: new Date(endDate.getTime() - oneDayInMillis),
		};
	}
	public getOrientationMode(activeRoute: string): string {
		if (activeRoute.includes('dashboard')) {
			const orientationMode = activeRoute
				.split('&')
				.find((val: string) => val.includes('orientationMode'))
				?.split('=')
				?.pop();
			if (+orientationMode === 1 || +orientationMode === 0 ) {
				return '2';
			} else {
				return '0';
			}
		}
		return '0';
	}
}
