import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { UrlNames } from 'src/app/core/services/urlProfiler';
import { BaseWebApiService } from 'src/app/core/services/_base-web-api.service';
import {
	getSelectedLocation,
	getUserId,
	SharedState,
} from 'src/app/shared/_state/shared.reducer';
import { BadgePayload } from '../_models/badges.model';
import { Reward } from '../_models/rewards.model';
import { UserReportsService } from '../../reports/_services/user-reports.service';

@Injectable({
	providedIn: 'root',
})
export class DashboardService {
	clientcode: string;
	pipe(arg0: MonoTypeOperatorFunction<unknown>) {
		throw new Error('Method not implemented.');
	}
	private _locationId: number;
	private _userId: number;
	constructor(
		private apiService: BaseWebApiService,
		private localData: LocalDataService,
		private userReportService: UserReportsService,
		private sharedStore: Store<SharedState>
	) {
		this.sharedStore
			.pipe(
				select(getSelectedLocation),
				filter((value) => !!value && !!value.location)
			)
			.subscribe({
				next: (res) => {
				this.clientcode = res?.location?.sitecode;
					this._locationId = res.location.id;
				},
			});
		this.sharedStore
			.pipe(
				select(getUserId),
				filter((value) => !!value)
			)
			.subscribe({
				next: (res) => (this._userId = res),
			});
	}

	public getOnLoadData(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}/${UrlNames.getDetailsOnLoadOfDashBoard}`,
			{
				userid: this._userId,
				locationId: this._locationId,
			}
		);
	}
	public getbroadcastdata(): Observable<any> {
		return this.apiService.get(
			`/${UrlNames.getDetailsOnLoadBroadcast}/${ this._userId}`,
			{
				clientCode: this.clientcode
				
			}
		);
		
	}
	public getMyRewards(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}/${UrlNames.getMyRewards}`,
			{
				userid: this._userId,
				locationId: this._locationId,
			}
		);
	}
	public downloadPdf(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}/${UrlNames.dashboardGetReward}/${UrlNames.pdf}`,
			{
				userId: this._userId,
				locationId: this._locationId,
				year: this.userReportService.currentEducationYear
			}
		);
	}
	public getManageRewards(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}/${UrlNames.getDetailsManageRewards}`,
			{
				userid: this._userId,
				locationId: this._locationId,
			}
		);
	}
	public getRecommendedCourses(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.myCoursesv3}${
				UrlNames.myCoursesRecommend
			}/${this.localData.getUserId()}`,
			{
				locationId: this.localData.getLocationId(),
			}
		);
	}
	public getHistoryCourses(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.myCourses}${UrlNames.myCoursesHistory}`,
			null
		);
	}
	public getMandotaryCourses(courseStatus: number): Observable<any> {
		return this.apiService.get(
			`${UrlNames.myCoursesv3}${
				UrlNames.myCoursesMandatory
			}/${this.localData.getUserId()}`,
			{
				status: courseStatus,
				educationYear: new Date().getFullYear(),
				hideCoursePastDue: null,
				locationId: this.localData.getLocationId(),
			}
		);
	}

	public getRegularBadges(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}/${UrlNames.dashboardGetRegularBadges}`,
			{
				userid: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
			}
		);
	}

	public getCustomBadges(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}/${UrlNames.dashboardGetCustomBadges}`,
			{
				userid: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
			}
		);
	}

	public getAllRewards(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}/${UrlNames.dashboardGetAllRewards}`,
			{
				userid: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
			}
		);
	}

	public addNewBadge(badgePayload: BadgePayload): Observable<any> {
		return this.apiService.post(
			`${UrlNames.dashboard}/${UrlNames.dashboardAddNewBadgeInConfiguration}`,
			badgePayload,
			{
				userid: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
			}
		);
	}

	public sendGratitudeBadge(badgePayload: BadgePayload): Observable<any> {
		return this.apiService.post(
			`${UrlNames.dashboard}/${UrlNames.sendGratitudeBadges}`,
			badgePayload,
			{
				userid: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
			}
		);
	}

	public editBadge(badgePayload: BadgePayload): Observable<any> {
		return this.apiService.put(
			`${UrlNames.dashboard}/${UrlNames.dashboardEditBadgesInConfig}/${badgePayload.badgeId}`,
			badgePayload,
			{
				userid: this.localData.getUserId(),
				badgeId: badgePayload.badgeId,
			}
		);
	}

	public addAdditionalLinks(
		payload: Array<{ name: string; url: string }>
	): Observable<Object> {
		const _payload = payload.map((x) => {
			return {
				locationId: this._locationId,
				linkName: x.name,
				linkUrl: x.url,
				createdBy: this._userId,
				updatedBy: this._userId,
			};
		});
		return this.apiService.post(
			`${UrlNames.dashboard}/${UrlNames.additionalLinks}`,
			_payload
		);
	}

	public removeAdditionalLinks(payload: Array<number>): Observable<Object> {
		return this.apiService.delete(
			`${UrlNames.dashboard}/${UrlNames.additionalLinks}`,
			payload
		);
	}

	public getAdditionalLinks(): Observable<Object> {
		return this.apiService.get(
			`${UrlNames.dashboard}/${UrlNames.additionalLinks}`,
			null
		);
	}

	public getTriggerTypesInBadges(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}/${UrlNames.dashboardGetTriggerTypesInBadges}`,
			null
		);
	}

	/**
	 * API to get default icons while creating new badges
	 * @returns - Observable of any
	 */
	public getOnLoadAddNewBadge(moduleName: string): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}/${UrlNames.dashboardOnLoadAddNewBadge}`,
			{
				moduleName: moduleName,
				locationId: this.localData.getLocationId(),
			}
		);
	}

	/**
	 * API to get Badges to link with rewards
	 * @returns - Observable of any
	 */
	public getAllBadgesToLink(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}/${UrlNames.dashboardGetAllBadgesToLink}`,
			{
				userid: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
			}
		);
	}

	public getBadge(badgeId: number): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}/${UrlNames.dashboardGetBadge}/${badgeId}`,
			null
		);
	}

	public getReward(rewardId: number): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}/${UrlNames.dashboardGetReward}/${rewardId}`,
			null
		);
	}

	public createReward(rewardPayload: Reward): Observable<any> {
		return this.apiService.post(
			`${UrlNames.dashboard}/${UrlNames.dashboardAddNewRewardInConfig}`,
			rewardPayload,
			{
				user: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
			}
		);
	}

	public editReward(rewardPayload: Reward): Observable<any> {
		return this.apiService.put(
			`${UrlNames.dashboard}/${UrlNames.dashboardEditRewardsInConfig}/${rewardPayload.rewardId}`,
			rewardPayload,
			{
				user: this.localData.getUserId(),
			}
		);
	}

	public redeemMyRewardsCollection(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}/${UrlNames.dashboardRedeemMyRewardsCollection}`,
			{
				userid: this._userId,
				locationId: this._locationId,
			}
		);
	}

	public viewSentGratitudeBadges(year: number): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}/${UrlNames.viewSentGratitudeBadges}`,
			{
				userid: this._userId,
				locationId: this._locationId,
				year: year,
			}
		);
	}

	public viewRewardRecord(year: number): Observable<any> {
		return this.apiService.get(
			`${UrlNames.dashboard}/${UrlNames.viewRewardRecord}`,
			{
				userid: this._userId,
				locationId: this._locationId,
				year: year,
			}
		);
	}

	public redeemMyRewards(rewardId: number): Observable<any> {
		return this.apiService.post(
			`${UrlNames.dashboard}/${UrlNames.dashboardRedeemMyRewards}`,
			null,
			{
				userid: this._userId,
				locationId: this._locationId,
				rewardId: rewardId,
			}
		);
	}

	public activateBadge(badgePayload: BadgePayload): Observable<any> {
		return this.apiService.put(
			`${UrlNames.dashboard}${UrlNames.activateBadge}/${badgePayload.badgeId}`,
			null,
			{
				badgeId: badgePayload.badgeId,
				locationId: this.localData.getLocationId(),
				userId: this.localData.getUserId(),
				isActive: badgePayload.activeFlag,
			}
		);
	}

	public uploadDefaultIcon(payload: any): Observable<any> {
		return this.apiService.post(
			`${UrlNames.dashboard}${UrlNames.uploadDefaultImages}`,
			payload,
			{
				userId: this._userId,
			}
		);
	}
}
