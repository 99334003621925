<div class="ms-auto mb-2" style="width: fit-content">
	<app-export-data
		[tableData]="tableContentsInput"
		[headerColumns]="tableColumns"
		[compliant]="compliant"
		[fileName]="fileName"
		[equalSizeColumns]="equalSizeColumns"
	></app-export-data>
</div>
<div class="report" [class.sticky-report]="stickyHead">
	<div class="table table-div" [class.table-responsive]="!stickyHead">
		<table class="table mb-0">
			<thead>
				<tr>
					<ng-container
						*ngFor="let header of tableColumns"
						scope="col"
						class="p-2"
					>
						<th *ngIf="header.isShown" class="p-3">
							<div
								(click)="sortTable(header)"
								class="d-flex align-items-center justify-content-start p-0 cursor-pointer"
								(keyup)="sortTable(header)"
							>
								<div>
									{{ header.columnName | translate }}
								</div>
								<div class="material-icons-outlined sort-icons">
									{{
										sortKey !== header.bindKey
											? "unfold_more"
											: sortDir === "DESC"
											? "expand_less"
											: "expand_more"
									}}
								</div>
							</div>
							<small *ngIf="header.subHead" class="p-0">
								({{ header.subHead | translate }})
							</small>
						</th>
					</ng-container>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of tableContents">
					<ng-container *ngFor="let header of tableColumns">
						<td class="p-2 color-gray-3" *ngIf="header.isShown">
							<a
								*ngIf="header?.redirectTo"
								[class.redirection]="header.redirectTo"
								[routerLink]="
									'/' +
									header?.redirectTo +
									'/' +
									row[header?.redirectUrlKeyColumn]
								"
								[queryParams]="{
									educationYear:
									header.bindKey === 'loginsThisyear' || header.bindKey === 'loginsEver'
											? extras
											: 0,
									active: router.url.split('/').pop(),
									fromDate:
									header.bindKey === 'loginsThisyear' 
											? row['startofYear']
											: header.bindKey === 'loginsEver' ? '2012-01-01' : null,
									toDate:
									header.bindKey === 'loginsThisyear' || header.bindKey === 'loginsEver'
											? row['endofYear']
											: null
								}"
								class="table-cell d-flex align-items-center user-link"
							>
								<span
									*ngIf="
										header.bindKey !== 'loginsEver' &&
										header.bindKey !== 'loginsThisyear'
									"
								>
									{{
										row[header.bindKey]
											? header.dateValue
												? header.bindKey ==
														"latestLogin" ||
												  header.bindKey ==
														"lastCompletedDate"
													? row[header.bindKey]
													: (row[header.bindKey]
													  | dateFormat)
												: row[header.bindKey]
											: header.numberColumn
											? 0
											: "-"
									}}
								</span>
								<span
									*ngIf="
										header.bindKey == 'loginsEver' ||
										header.bindKey == 'loginsThisyear'
									"
								>
									{{ row[header.bindKey] }}</span
								>
								<i
									*ngIf="
										header.refName &&
										header.refName === 'profileLinkId' &&
										row[header.refName]
									"
									class="ms-2 material-icons-outlined small-icon color-blue-2"
									[matTooltip]="
										'LINKED_TO_ANOTHER_HOME' | translate
									"
								>
									link
							</i>
							</a>

							<div
								*ngIf="!header.redirectTo"
								class="table-cell d-flex align-items-center"
								(click)="redirectUser(row, header)"
								(keyup) ="redirectUser(row, header)"
							>
								{{
									row[header.bindKey]
										? header.dateValue
											? header.bindKey == "latestLogin" ||
											  header.bindKey ==
													"lastCompletedDate"
												? row[header.bindKey]
												: (row[header.bindKey]
												  | dateFormat)
											: row[header.bindKey]
										: header.numberColumn
										? 0
										: "-"
								}}
								<i
									*ngIf="
										header.refName &&
										header.refName === 'profileLinkId' &&
										row[header.refName]
									"
									class="ms-2 material-icons-outlined small-icon color-blue-2"
									[matTooltip]="
										'LINKED_TO_ANOTHER_HOME' | translate
									"
								>
									link
							</i>
							</div>
						</td>
					</ng-container>
				</tr>
				<tr *ngIf="!tableContents.length">
					<td [colSpan]="tableColumns.length">
						{{ "NO_DATA_TO_SHOW" | translate }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<div class="mt-3 d-flex col-12">
	<div class="float-start">
		<ng-select
			[items]="localData.pageLimits"
			[(ngModel)]="limit"
			[clearable]="false"
			[searchable]="false"
			(change)="changePageLimit()"
		></ng-select>
	</div>
	<div class="ms-auto">
		<app-pagination
			[collectionSize]="tableContentsInput.length"
			[pageSize]="
				limit === localData.allContents
					? tableContentsInput.length
					: limit
			"
			[page]="limit === localData.allContents ? 1 : offset / limit + 1"
			(pageChange)="changePage($event)"
		></app-pagination>
	</div>
</div>
