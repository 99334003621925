export enum SlrUrlQueryParams {
	SHOW_TEST_ANSWERS = 'sta',
	IS_REATTEMPT = 'ir',
	ATTEMPT_NUMBER = 'an',
	LOGIN_REDIRECT = 'redirect_uri',

	// My Course Related
	COURSE_ID = 'cid',
	USER_ASSIGNMENT_ID = 'uaid',
	ASSIGNMENT_ID = 'aid',
	COURSE_OPENED_FROM = 'cof',
	SELF_ASSIGNED = 'selfAssignedCourse',
	USER_TEST_HISTORY_ID = 'userTestHistoryId',
	TEST_TYPE = 'testType',
	LEARNINGPATH_ID = 'lpid',
	SHOW_CONGRATULATIONS = 'showCongratulation',
	IS_LEARNINGPAHT = 'islp',
	COURSE_TYPE = 'courseType',
	ADD = 'add',

	TEST_ID = 'tid',
	SURVEY_ID = 'sid',
	ACTIVE_STEP = 'activestep',
	SELECTED_INSTANCE = 'instance',
	POLICY_IN_REVIEW = 'pir',
	SEARCH_QUERY = 'search_query',
	FROM_REPORTS = 'fromReport',
	FROM_EVENT = 'fromEvent',
	MODULE = 'module',
	USER_ID = 'uid',
	REQUIRED_BY_DATE = 'rbd',

	// RC
	COPY_COURSE = 'copyCourseId',

	//General
	REDIRECT_PATH = 'redirectTo',
	NEW_USER = 'newuser',
	LANGUAGE = 'lang',
}
