// Angular Modules
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ColorPickerModule } from 'ngx-color-picker';
import { QuillModule } from 'ngx-quill';
import { environment } from 'src/environments/environment';
import { DateInputFormatDirective } from '../core/directives/date-input-format.directive';
import { IsAuthorisedDerective } from '../core/directives/is-authorised.directive';
import { SLRPaginatorDirective } from '../core/directives/slr-paginator.derective';
import { TableIconDirective } from '../core/directives/table-icon.directive';
import { TableLegendDirective } from '../core/directives/table-legend.directive';
import { ErrorInterceptor } from '../core/interceptors/error.interceptor';
import { HeaderInterceptor } from '../core/interceptors/header.interceptor';
import { ScormHeaderInterceptor } from '../core/interceptors/scrom-header.interceptor';
import { DataPropertyGetterPipe } from '../core/pipes/data-property-getter.pipe';
import { DateConverterPipe, SLRDatePipe } from '../core/pipes/slr-date.pipe';
import {
	UrlProvider,
	UrlProviderDev,
	UrlProviderProd,
	UrlProviderTest,
} from '../core/services/url.provider';
import { AuthenticationEffects } from '../modules/authentication/_state/authentication.effects';
import { FooterComponent as ChatFooterComponent } from '../modules/broadcast-and-messaging/chat-components/content/footer/footer.component';
import { ThreadCardComponent } from '../modules/broadcast-and-messaging/chat-components/sidebar/left-sidebar/thread-card/thread-card.component';
import { ChatEffects } from '../modules/broadcast-and-messaging/_state/chat.effects';
import { chatReducer } from '../modules/broadcast-and-messaging/_state/chat.reducer';
import { AddUserSkillComponent } from '../modules/manage-users/user-skills/add-user-skill/add-user-skill.component';
import { CommonSurveyPopupComponent } from '../modules/my-courses/common-survey-popup/common-survey-popup.component';
import { AddContentComponent } from '../modules/resource-center/create-resource/uploads/add-content/add-content.component';
import { UploadedFileEntryComponent } from '../modules/resource-center/create-resource/uploads/uploaded-file-entry/uploaded-file-entry.component';
import { UserProfileEffets } from '../modules/user-profile/_state/user-profile.effects';
import { userProfileReducer } from '../modules/user-profile/_state/user-profile.reducer';
import { CancelButtonComponent } from './cancel-button/cancel-button.component';
import { BadgeComponent } from './components/badge/badge.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { SlrBtn01Component } from './components/buttons/slr-btn01/slr-btn01.component';
import { SlrBtn02Component } from './components/buttons/slr-btn02/slr-btn02.component';
import { SlrBtn03Component } from './components/buttons/slr-btn03/slr-btn03.component';
import { SlrBtn04Component } from './components/buttons/slr-btn04/slr-btn04.component';
import { ChatPopupComponent } from './components/chat-popup/chat-popup.component';
import { CircularProgressComponent } from './components/circular-progress/circular-progress.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { CourseInfoCardsComponent } from './components/course-info-cards/course-info-cards.component';
import { CustomNgSelectComponent } from './components/custom-ng-select/custom-ng-select.component';
import { CustomizeColumnsComponent } from './components/customize-columns/customize-columns.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { SlrDpr02Component } from './components/dropdowns/slr-dpr02/slr-dpr02.component';
import { SlrDrp01Component } from './components/dropdowns/slr-drp01/slr-drp01.component';
import { TabbedLocationDropdownComponent } from './components/dropdowns/tabbed-location-dropdown/tabbed-location-dropdown.component';
import { ExportDataComponent } from './components/export-data/export-data.component';
import { FilePreviewComponent } from './components/file-preview/file-preview.component';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { FormWizardComponent } from './components/form-wizard/form-wizard.component';
import { AuthCodeInputComponent } from './components/inputs/auth-code-input/auth-code-input.component';
import { SlrInputFileUploadComponent } from './components/inputs/slr-input-file-upload/slr-input-file-upload.component';
import { SlrInputGrayComponent } from './components/inputs/slr-input-gray/slr-input-gray.component';
import { SlrInputWithDrpComponent } from './components/inputs/slr-input-with-drp/slr-input-with-drp.component';
import { SlrInputComponent } from './components/inputs/slr-input/slr-input.component';
import { SlrInput02Component } from './components/inputs/slr-input02/slr-input02.component';
import { SlrPasswordTbComponent } from './components/inputs/slr-password-tb/slr-password-tb.component';
import { SlrPasswordTb02Component } from './components/inputs/slr-password-tb02/slr-password-tb02.component';
import { SlrRbtnGroupComponent } from './components/inputs/slr-rbtn-group/slr-rbtn-group.component';
import { SlrRbtn01Component } from './components/inputs/slr-rbtn01/slr-rbtn01.component';
import { SlrTextareaComponent } from './components/inputs/slr-textarea/slr-textarea.component';
import { SlrUsernameTbComponent } from './components/inputs/slr-username-tb/slr-username-tb.component';
import { LocationTreeComponent } from './components/location-tree/location-tree.component';
import { SlrMenuComponent } from './components/menu/slr-menu/slr-menu.component';
import { TabFiltersComponent } from './components/misc/tab-filters/tab-filters.component';
import { ModalHeaderPlaceholderComponent } from './components/modal-header-placeholder/modal-header-placeholder.component';
import { MultiSelectChkbComponent } from './components/multi-select-chkb/multi-select-chkb.component';
import { NewConversationPopupComponent } from './components/new-conversation-popup/new-conversation-popup.component';
import { NotificationContainerComponent } from './components/notification-container/notification-container.component';
import { PdfButtonComponent } from './components/pdf-button/pdf-button.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ReportTableComponent } from './components/report-table/report-table.component';
import { SiteLocationComponent } from './components/site-location/site-location.component';
import { SkillLocationTreeComponent } from './components/skill-location-tree/skill-location-tree.component';
import { SlrDateInputComponent } from './components/slr-date-input/slr-date-input.component';
import { SlrLoaderComponent } from './components/slr-loader/slr-loader.component';
import { SlrToggleComponent } from './components/slr-toggle/slr-toggle.component';
import { AddCommentPopupComponent } from './components/tables/skills-table/add-comment-popup/add-comment-popup.component';
import { AddSkillCertificatePopupComponent } from './components/tables/skills-table/add-skill-certificate-popup/add-skill-certificate-popup.component';
import { SkillEntryComponent } from './components/tables/skills-table/skill-entry/skill-entry.component';
import { SkillRowLegendDirective } from './components/tables/skills-table/skill-row-legend.directive';
import { SkillsTableComponent } from './components/tables/skills-table/skills-table.component';
import { SlrDirTableComponent } from './components/tables/slr-dir-table/slr-dir-table.component';
import { SlrTableComponent } from './components/tables/slr-table/slr-table.component';
import { UserImageComponent } from './components/user-image/user-image.component';
import { ViewSwitcherComponent } from './components/view-switcher/view-switcher.component';
import { CheckMessageExpiry } from './directives/check-message-expiry.directive';
import { DateFormatPipe } from './directives/date-format.pipe';
import { DurationFormatPipe } from './directives/duration-format.pipe';
import { GetPercentagePipe } from './directives/get-percentage.pipe';
import { GetSkillClassPipe } from './directives/get-skill-class.pipe';
import { GetCourseStatusClassPipe } from './directives/get-course-status-class.pipe';
import { IsDocumentDirective } from './directives/is-document.directive';
import { PrecisionDirective } from './directives/precision.directive';
import { StringDateFormatPipe } from './directives/string-date-format.pipe';
import { BreadcrumbComponent } from './layouts/breadcrumb/breadcrumb.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { HeaderComponent } from './layouts/header/header.component';
import { HelpComponent } from './layouts/help/help.component';
import { PageNotFoundComponent } from './layouts/page-not-found/page-not-found.component';
import { PrivateLayoutComponent } from './layouts/private-layout/private-layout.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { MaterialModule } from './material/material.module';
import { PaginationComponent } from './pagination/pagination.component';
import { DownloadCertificateComponent } from './Reports/download-certificate/download-certificate/download-certificate.component';
import { DueDateComponent } from './Reports/due-date/due-date.component';
import { LpIconComponent } from './Reports/lp-icon/lp-icon.component';
import { ReportFooterComponent } from './Reports/report-footer/report-footer.component';
import { ReportSummaryCardComponent } from './Reports/report-summary-card/report-summary-card.component';
import { ReportTableLayoutComponent } from './Reports/report-table-layout/report-table-layout.component';
// Components
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { TableActionButtonDirective } from '../core/directives/table-action-button.directive';
import { TestOptionComponent } from '../modules/my-courses/test-option/test-option.component';
import { TestQuestionComponent } from '../modules/my-courses/test-question/test-question.component';
import { SurveytOptionComponent } from '../modules/survey/survey-option/survey-option.component';
import { SlrDrp03Component } from './components/dropdowns/slr-drp03/slr-drp03.component';
import { SlrNumberInputComponent } from './components/inputs/slr-number-input/slr-number-input.component';
import { SlrTimeInputComponent } from './components/inputs/slr-time-input/slr-time-input.component';
import { YearMonthInputComponent } from './components/inputs/year-month-input/year-month-input.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { EditorOpenMessageComponent } from './components/misc/editor-open-message/editor-open-message.component';
import { MultibarChartComponent } from './components/multibar-chart/multibar-chart.component';
import { NotificationPopupComponent } from './components/notification-popup/notification-popup.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { SkillCertificateIconComponent } from './components/skill-icons/skill-certificate-icon/skill-certificate-icon.component';
import { SkillCommentIconComponent } from './components/skill-icons/skill-comment-icon/skill-comment-icon.component';
import { DurationInHourPipe } from './directives/duration-in-hour.pipe';
import { UnsubscribeComponent } from './layouts/unsubscribe/unsubscribe.component';
import { SaveButtonComponent } from './save-button/save-button.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { SubmitButtonComponent } from './submit-button/submit-button.component';
import { SharedEffects } from './_state/shared.effets';
import { BroadcastMessageComponent } from './components/broadcast-message/broadcast-message.component';
import { BroadcastPopupComponent } from './components/broadcast-popup/broadcast-popup.component';
import { SlrColorInputComponent } from './components/inputs/slr-color-input/slr-color-input.component';
import { NgxEditorModule } from 'ngx-editor';
import { SlrNgxEditorComponent } from './components/inputs/slr-ngx-editor/slr-ngx-editor.component';
import { BroadcastdeleteComponent } from './components/broadcastdelete/broadcastdelete.component';
import { DeletesurveypopupComponent } from './components/deletesurveypopup/deletesurveypopup.component';
import { GetStringPercentPipe } from './directives/get-string-percent.pipe';
import { SlrDrp06Component } from './components/dropdowns/slr-drp06/slr-drp06.component';
import { BroadcastListComponent } from './components/broadcast-popup/broadcast-list/broadcast-list.component';
import { CompletionPercentagePipe } from './directives/completion-percentage.pipe';
import { SkillStatusTrasnlatePipe } from './pipes/skill-status.pipe';
import { SanitizeInterceptor } from '../core/interceptors/sanitize.interceptor';
import { NgPaginationComponent } from './ng-pagination/ng-pagination.component';
import { NewUserTutorialPopupComponent } from './components/new-user-tutorial-popup/new-user-tutorial-popup.component';
import { ReportCategoryColspanPipe } from '../modules/reports/course-reports/report-category-colspan.pipe';
import { AnniversaryDateInputComponent } from '../modules/manage-users/add-new-user/anniversary-date-input/anniversary-date-input.component';

@NgModule({
	declarations: [
		SaveButtonComponent,
		SubmitButtonComponent,
		CancelButtonComponent,
		SearchBoxComponent,
		PaginationComponent,
		PrivateLayoutComponent,
		HeaderComponent,
		SidebarComponent,
		BreadcrumbComponent,
		SiteLocationComponent,
		FooterComponent,
		DropdownComponent,
		ConfirmationModalComponent,
		FormWizardComponent,
		PrecisionDirective,
		NotificationContainerComponent,
		CustomNgSelectComponent,
		ProgressBarComponent,
		SlrInputGrayComponent,
		SlrTextareaComponent,
		SlrInputFileUploadComponent,
		SlrDrp01Component,
		SlrBtn01Component,
		SlrInputComponent,
		SlrRbtn01Component,
		SLRPaginatorDirective,
		TableIconDirective,
		TableActionButtonDirective,
		SlrRbtnGroupComponent,
		SlrBtn02Component,
		SlrBtn03Component,
		SlrBtn04Component,
		BadgeComponent,
		SlrUsernameTbComponent,
		SlrPasswordTbComponent,
		SlrLoaderComponent,
		SlrTableComponent,
		DataPropertyGetterPipe,
		GetSkillClassPipe,
		GetCourseStatusClassPipe,
		MultiSelectChkbComponent,
		IsAuthorisedDerective,
		SlrDateInputComponent,
		SLRDatePipe,
		DateConverterPipe,
		GetPercentagePipe,
		SkillStatusTrasnlatePipe,
		DateInputFormatDirective,
		TableLegendDirective,
		DateFormatPipe,
		StringDateFormatPipe,
		CourseInfoCardsComponent,
		IsDocumentDirective,
		CheckMessageExpiry,
		CircularProgressComponent,
		UploadedFileEntryComponent,
		SlrToggleComponent,
		AddContentComponent,
		TabbedLocationDropdownComponent,
		FilePreviewComponent,
		SlrInputWithDrpComponent,
		AddUserSkillComponent,
		PageNotFoundComponent,
		SlrInput02Component,
		SlrDpr02Component,
		TabFiltersComponent,
		ViewSwitcherComponent,
		ChatPopupComponent,
		ThreadCardComponent,
		NewConversationPopupComponent,
		ChatFooterComponent,
		AuthCodeInputComponent,
		CustomizeColumnsComponent,
		ReportTableComponent,
		BarChartComponent,
		DurationFormatPipe,
		FilterDropdownComponent,
		ExportDataComponent,
		UserImageComponent,
		SkillsTableComponent,
		SkillEntryComponent,
		SkillRowLegendDirective,
		LocationTreeComponent,
		SkillLocationTreeComponent,
		DoughnutChartComponent,
		AddCommentPopupComponent,
		AddSkillCertificatePopupComponent,
		SlrMenuComponent,
		HelpComponent,
		SlrDirTableComponent,
		DownloadCertificateComponent,
		ReportFooterComponent,
		ModalHeaderPlaceholderComponent,
		SlrPasswordTb02Component,
		ReportSummaryCardComponent,
		LpIconComponent,
		PdfButtonComponent,
		DueDateComponent,
		CommonSurveyPopupComponent,
		ReportTableLayoutComponent,
		PieChartComponent,
		LineChartComponent,
		MultibarChartComponent,
		NotificationPopupComponent,
		UnsubscribeComponent,
		EditorOpenMessageComponent,
		SurveytOptionComponent,
		TestQuestionComponent,
		TestOptionComponent,
		SlrTimeInputComponent,
		SlrDrp03Component,
		YearMonthInputComponent,
		DurationInHourPipe,
		SlrNumberInputComponent,
		SkillCommentIconComponent,
		SkillCertificateIconComponent,
		BroadcastMessageComponent,
		BroadcastPopupComponent,
		SlrColorInputComponent,
		SlrNgxEditorComponent,
		BroadcastdeleteComponent,
		DeletesurveypopupComponent,
		GetStringPercentPipe,
		SlrDrp06Component,
		BroadcastListComponent,
		CompletionPercentagePipe,
		NgPaginationComponent,
		NewUserTutorialPopupComponent,
		ReportCategoryColspanPipe,
		AnniversaryDateInputComponent
	],
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		RouterModule,
		NgSelectModule,
		MaterialModule,
		YouTubePlayerModule,
		QuillModule.forRoot(),
		DragDropModule,
		NgCircleProgressModule.forRoot(),
		StoreModule.forFeature('slr-chat', chatReducer),
		StoreModule.forFeature('user-profile', userProfileReducer),
		EffectsModule.forFeature([
			AuthenticationEffects,
			ChatEffects,
			SharedEffects,
			UserProfileEffets,
		]),
		ColorPickerModule,
		MatTreeModule,
		NgxSliderModule,
		NgxEditorModule,
	],
	exports: [
		NgbModule,
		SaveButtonComponent,
		SubmitButtonComponent,
		TranslateModule,
		CancelButtonComponent,
		SearchBoxComponent,
		PaginationComponent,
		ConfirmationModalComponent,
		FormWizardComponent,
		PrecisionDirective,
		SLRPaginatorDirective,
		TableIconDirective,
		TableActionButtonDirective,
		CustomNgSelectComponent,
		ProgressBarComponent,
		SlrInputGrayComponent,
		SlrTextareaComponent,
		SlrInputFileUploadComponent,
		PieChartComponent,
		DropdownComponent,
		SlrDrp01Component,
		SlrBtn01Component,
		SlrInputComponent,
		SlrRbtn01Component,
		SlrRbtnGroupComponent,
		SlrUsernameTbComponent,
		SlrPasswordTbComponent,
		SlrLoaderComponent,
		MaterialModule,
		SlrBtn02Component,
		SlrBtn03Component,
		SlrBtn04Component,
		BadgeComponent,
		QuillModule,
		MatButtonToggleModule,
		NotificationContainerComponent,
		SlrTableComponent,
		MultiSelectChkbComponent,
		IsAuthorisedDerective,
		DragDropModule,
		SlrDateInputComponent,
		SLRDatePipe,
		DateConverterPipe,
		DateInputFormatDirective,
		TableLegendDirective,
		DateFormatPipe,
		StringDateFormatPipe,
		CourseInfoCardsComponent,
		CircularProgressComponent,
		UploadedFileEntryComponent,
		SlrToggleComponent,
		AddContentComponent,
		TabbedLocationDropdownComponent,
		FilePreviewComponent,
		SlrInputWithDrpComponent,
		AddUserSkillComponent,
		SlrInput02Component,
		SlrDpr02Component,
		TabFiltersComponent,
		IsDocumentDirective,
		CheckMessageExpiry,
		ColorPickerModule,
		ViewSwitcherComponent,
		ChatPopupComponent,
		ThreadCardComponent,
		ChatFooterComponent,
		AuthCodeInputComponent,
		CustomizeColumnsComponent,
		ReportTableComponent,
		BarChartComponent,
		DurationFormatPipe,
		FilterDropdownComponent,
		DataPropertyGetterPipe,
		SkillStatusTrasnlatePipe,
		ExportDataComponent,
		UserImageComponent,
		SkillsTableComponent,
		MatTreeModule,
		LocationTreeComponent,
		SkillLocationTreeComponent,
		DoughnutChartComponent,
		MatSlideToggleModule,
		GetPercentagePipe,
		NgSelectModule,
		SlrMenuComponent,
		SlrDirTableComponent,
		DownloadCertificateComponent,
		ReportFooterComponent,
		ModalHeaderPlaceholderComponent,
		SlrPasswordTb02Component,
		ReportSummaryCardComponent,
		LpIconComponent,
		GetSkillClassPipe,
		GetCourseStatusClassPipe,
		PdfButtonComponent,
		DueDateComponent,
		CommonSurveyPopupComponent,
		ReportTableLayoutComponent,
		LineChartComponent,
		MultibarChartComponent,
		TestQuestionComponent,
		// TestOptionComponent,
		SurveytOptionComponent,
		SlrTimeInputComponent,
		SlrDrp03Component,
		YearMonthInputComponent,
		DurationInHourPipe,
		SlrNumberInputComponent,
		YouTubePlayerModule,
		NgxSliderModule,
		SkillCommentIconComponent,
		SkillCertificateIconComponent,
		BroadcastMessageComponent,
		BroadcastPopupComponent,
		SlrColorInputComponent,
		NgxEditorModule,
		SlrNgxEditorComponent,
		BroadcastdeleteComponent,
		DeletesurveypopupComponent,
		GetStringPercentPipe,
		SlrDrp06Component,
		CompletionPercentagePipe,
		NgPaginationComponent,
		ReportCategoryColspanPipe,
		AnniversaryDateInputComponent
	],
	providers: [
		{
			provide: UrlProvider,
			useClass: environment.production
				? UrlProviderProd
				: environment.test
				? UrlProviderTest
				: UrlProviderDev,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: SanitizeInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HeaderInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ScormHeaderInterceptor,
			multi: true,
		},
		DateFormatPipe,
		TranslatePipe,
		DurationFormatPipe,
		GetPercentagePipe,
		DurationInHourPipe,
		GetStringPercentPipe,
	],
})
export class SharedModule {}
