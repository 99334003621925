import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ExportToCsv } from 'export-to-csv';
import htmlToPdfmake from 'html-to-pdfmake';
import jsPDF from 'jspdf';
import autoTable, { RowInput, Styles, UserOptions } from 'jspdf-autotable';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { getBase64Logo } from 'src/app/core/services/getBase64logo';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { exportCsvOptions } from 'src/app/shared/components/export-data/export-data.options';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { DateFormatPipe } from 'src/app/shared/directives/date-format.pipe';
import { GetPercentagePipe } from 'src/app/shared/directives/get-percentage.pipe';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
	providedIn: 'root',
})
export class ExportReportsService {
	private defaultTableStyle: Partial<Styles> = {
		lineWidth: 0.2,
	};
	public defaultAutotableStyles: UserOptions = {
		margin: {
			top: 20,
		},
		styles: {
			lineWidth: 0.2,
		},
	};
	public titleRowStyles: Partial<Styles> = {
		fillColor: '#e8f0f9',
		fontSize: 14,
	};
	public locationName: string = JSON.parse(
		localStorage.getItem('selectedLocation')
	)?.name;
	public logoUrl$: BehaviorSubject<string> = new BehaviorSubject<string>('');
	private unsubscribe$ = new Subject();
	constructor(
		private localData: LocalDataService,
		private dateFormatter: DateFormatPipe,
		private translatePipe: TranslatePipe,
		private percentagePipe: GetPercentagePipe,
		private router: Router,
		private translate: TranslateService
	) {
		this.getSurgeLogo();
	}
	public getDefaultAutotableStyles(minWidth?: number): UserOptions {
		return {
			margin: {
				top: 20,
			},
			styles: {
				lineWidth: 0.2,
				...(minWidth && { minCellWidth: minWidth }),
			},
		};
	}
	public exportCsv(
		headers: string[],
		body: string[][],
		filename: string
	): void {
		const head: string[] = [
			'Location Name',
			this.locationName,
			...Array(headers.length - 2).fill(''),
		];
		let csvExporter = new ExportToCsv({
			...exportCsvOptions,
			useKeysAsHeaders: false,
			headers: head,
			filename,
		});
		csvExporter.generateCsv([headers, ...body]);
		csvExporter = null;
	}
	public exportPdf(
		head: RowInput[],
		body: RowInput[],
		fileName: string = 'report',
		filter?: string[],
		styles: Partial<Styles> = this.defaultTableStyle
	): void {
		const doc = new jsPDF();
		const minCellWidth = this.getTableCellWidth(
			doc.internal.pageSize.width,
			head.length
		);
		let y = 10;
		if (this.router.url.includes('individual-survey')) {
			this.translate
				.get(['CATEGORY_NAME', 'SURVEY_NAME', 'STATUS', 'INDIVIDUAL_SURVEY_REPORT'])
				.pipe(takeUntil(this.unsubscribe$))
				.subscribe({
					next: (res) => {
						doc.setFontSize(16);
						doc.text(`${res.INDIVIDUAL_SURVEY_REPORT}`, 15, 25);
						doc.setFontSize(12);
						doc.text(`${res.CATEGORY_NAME}: ${filter[0]}`, 15, 35);
						doc.text(`${res.SURVEY_NAME}: ${filter[1]}`, 15, 42);
						doc.text(`${res.STATUS}: ${filter[2]}`, 15, 49);
						y = 40;
					},
				});
		}
		if (this.router.url.includes('pending-approval')) {
			this.translate
				.get(['SKILLS_PENDING_APPROVAL', 'EDUCATION_GROUPS', 'SKILLS'])
				.pipe(takeUntil(this.unsubscribe$))
				.subscribe({
					next: (res) => {
						doc.setFontSize(16);
						doc.text(`${res.SKILLS_PENDING_APPROVAL}`, 15, 25);
						doc.setFontSize(12);
						doc.text(`${res.SKILLS}: ${filter[1]}`, 15, 35);
						doc.text(`${res.EDUCATION_GROUPS}: ${filter[0]}`, 15, 42);
						y = 42;
					},
				});
		}
		if (this.router.url.includes('coming-due')) {
			this.translate
				.get(['SKILLS_COMING_DUE', 'EDUCATION_GROUPS','RENEW_DAYS_AWAY', 'SKILLS'])
				.pipe(takeUntil(this.unsubscribe$))
				.subscribe({
					next: (res) => {
						doc.setFontSize(16);
						doc.text(`${res.SKILLS_COMING_DUE}`, 15, 25);
						doc.setFontSize(12);
						doc.text(`${res.SKILLS}: ${filter[2]}`, 15, 35);
						doc.text(`${res.RENEW_DAYS_AWAY}: ${filter[1]}`, 15, 42);
						doc.text(`${res.EDUCATION_GROUPS}: ${filter[0]}`, 15, 49);
						y = 49;
					},
				});
		}
		autoTable(doc, {
			startY: y + 15,
			head,
			body,
			...this.getDefaultAutotableStyles(minCellWidth),
			styles,
		});
		this.addPdfHeaders(doc);
		let documentName = `${fileName}`;
		doc.setProperties({ title: documentName });
		const pdfBlob = doc.output('blob');
		const url = URL.createObjectURL(pdfBlob);
		const link = document.createElement('a');
		link.href = url;
		link.download = `${fileName}.pdf`;
		link.click();
		URL.revokeObjectURL(url);
		//this.openDocInNewTab(doc);
	}
	public getXoffset(text: string, doc: jsPDF): number {
		return doc.internal.pageSize.width - doc.getStringUnitWidth(text) * 5;
	}
	public getSurgeLogo() {
		getBase64Logo().then((url: string) => {
			this.logoUrl$.next(url);
		});
	}
	public addPdfHeaders(doc: jsPDF): void {
		const logo: string = this.logoUrl$.value;
		const date: string = this.dateFormatter.transform(
			this.localData.getToday()
		);
		const xOffset: number = this.getXoffset(
			date.length > this.locationName.length ? date : this.locationName,
			doc
		);
		const yOffset: number = doc.internal.pageSize.height - 5;
		let i: number = 1;
		const docSize: number = doc.getNumberOfPages();
		while (i <= docSize) {
			doc.setPage(i);
			doc.addImage({
				imageData: logo,
				x: 13,
				y: 5,
				format: 'PNG',
				width: 20,
				height: 10,
			});
			doc.setFontSize(10);
			doc.text(date, xOffset, 10);
			doc.text(this.locationName, xOffset, 15);
			doc.text(`Page ${i} of ${docSize}`, xOffset, yOffset);
			i++;
		}
	}
	public downloadHtml(htmlString: string, fileName: string): void {
		var html = htmlToPdfmake(htmlString);
		const dd: TDocumentDefinitions = {
			content: html,
			pageMargins: [10, 65, 10, 35],
			header: {
				columns: [
					{
						image: this.logoUrl$.value,
						height: 50,
						width: 70,
						style: 'logo',
					},
					{
						text: this.dateFormatter.transform(
							this.localData.getToday()
						),
						style: 'rightHeader',
					},
					{
						text: this.locationName,
						style: 'rightHeader',
					},
				],
			},
			footer: (p, size) => {
				return {
					text: `Page ${p} of ${size}`,
					style: {
						alignment: 'center',
					},
				};
			},
			styles: {
				logo: {
					margin: [10, 4],
					alignment: 'left',
				},
				rightHeader: {
					margin: [10, 8],
					alignment: 'right',
				},
				courseName: {
					fontSize: 18,
				},
			},
		};
		pdfMake.createPdf(dd).download(fileName);
	}

	public downloadHelpHtml(htmlString: string, fileName: string): void {
		var html = htmlToPdfmake(htmlString, {
			imagesByReference: true,
		});
		const dd: TDocumentDefinitions = {
			content: html.content,
			pageBreakBefore: (node: any) => {
				if (node.style && Array.isArray(node.style)) {
					return node.style.indexOf('pdf-pagebreak-before') > -1;
				}
				return false;
			},
			images: html.images,
			pageMargins: [10, 65, 10, 35],
			header: {
				columns: [
					{
						image: this.logoUrl$.value,
						height: 30,
						width: 70,
						style: 'logo',
					},
					{
						text: `${this.dateFormatter.transform(
							this.localData.getToday()
						)}\n${this.locationName}`,
						style: 'rightHeader',
					},
				],
			},
			footer: (p, size) => {
				return {
					text: `Page ${p} of ${size}`,
					style: {
						alignment: 'center',
					},
				};
			},
			styles: {
				logo: {
					margin: [10, 4],
					alignment: 'left',
				},
				rightHeader: {
					margin: [10, 8],
					alignment: 'right',
				},
				courseName: {
					fontSize: 18,
				},
			},
		};
		pdfMake.createPdf(dd).open();
	}

	/**
	 * To get formatted description content.
	 * @param replaceValues - Name, Author and duration of course/LP.
	 * @param isLp - Whether it's an LP or a course.
	 * @param desc - html template of description
	 */
	public formatResourceDescription(
		replaceValues: string[],
		desc: string,
		isLp: boolean = false
	): string {
		const labels: string[] = [
			isLp ? 'LEARNING_PATHWAY_OUTLINE' : 'COURSE_OUTLINE',
			'AUTHOR',
			'DURATION',
		].map((content) => this.getTranslatedContent(content));
		let template: string = AppConstants.resourceDescriptionContents;
		let i = 0;
		while (i <= 2) {
			template = template.replace(`L${i}`, labels[i]);
			template = template.replace(`val${i}`, replaceValues[i]);
			i++;
		}
		return template + desc;
	}
	public getTranslatedContent(string: string): string {
		return this.translatePipe.transform(string);
	}
	public getPercentage(val: number, total: number): number {
		return this.percentagePipe.transform(val, total);
	}
	public getTranslatedHeaders(headers: string[]): string[] {
		return headers.map((str) => this.getTranslatedContent(str));
	}
	public getTransformedDate(date: string): string {
		return this.dateFormatter.transform(date);
	}
	/**return min width of cells according to number of columns the table has
	 * @param width - JsPdf doc width
	 * @param totalColumns - number of columns, the table has
	 */
	public getTableCellWidth(width: number, totalColumns: number): number {
		const cellWidth = (width - 20) / totalColumns;
		const minCellWidth = cellWidth > 20 ? 20 : cellWidth;
		return minCellWidth;
	}
	public openDocInNewTab(doc: jsPDF): void {
		try {
			window.open(doc.output('bloburl'));
		} catch (error) {}
	}
}
