import { PermissionHelper } from 'src/app/core/helpers/permission-helper.class';
import { ManageUsersModulePrivilegesEnum } from 'src/app/core/models/_module_enums/manage-users-module.privileges.enum';
import { ReportModulePrivilegesEnum } from 'src/app/core/models/_module_enums/reports-module.privilege.enum';
import { resourceCenterActions } from 'src/app/core/models/_module_enums/resource-center-actions.enum';
import {
	CustomTableColumn,
	OverviewGraphModel,
	ReportTabs,
} from 'src/app/modules/reports/_models/reports-overview.model';
import { CourseInfoCard } from '../components/course-info-cards/course-info-cards.model';
import { DropdownInterface } from '../components/dropdowns/slr-drp01/dropdown.model';
import { AssignmentsTableHeader } from '../models/assignments.model';
import { ResourceTab } from '../models/categories';
import { Locations } from '../models/locations';
import { GroupsTableHeader } from '../models/resource-center-groups';
import { ReportFilters } from 'src/app/modules/reports/report.filters';
import { SurveysModulePrivilegesEnum } from 'src/app/core/models/_module_enums/surveys-module-privileges.enum';

export class AppConstants {
	public static permissionHelper = new PermissionHelper();
	public static usernameStyleData: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'jgraham',
			visibleName: 'jgraham',
		},
		{
			id: 2,
			name: 'jamieg',
			visibleName: 'jamieg',
		},
		{
			id: 3,
			name: 'grahamj',
			visibleName: 'grahamj',
		},
		{
			id: 4,
			name: 'jamie.graham',
			visibleName: 'jamie.graham',
		},
		{
			id: 5,
			name: 'graham.jamie',
			visibleName: 'graham.jamie',
		},
	];
	public static skillTypeData: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'SPECIFIC_TO_EDUCATION_GROUP',
		},
		{
			id: 2,
			name: 'ALL_SKILLS',
		},
	];
	public static locations: Locations[] = [
		{
			name: 'Quality Nursing Aurora',
			childrenLocation: [
				{
					name: 'Child 01',
					checked: true,
				},
				{
					name: 'Child 02',
					checked: true,
				},
				{
					name: 'Child 03',
					checked: true,
				},
			],
			toggled: false,
			checked: true,
			toggleIcon: 'expand_more',
		},
		{
			name: 'Quality Nursing Newmarket',
			childrenLocation: [
				{
					name: 'Child 01',
					checked: true,
				},
				{
					name: 'Child 02',
					checked: true,
				},
				{
					name: 'Child 03',
					checked: true,
				},
			],
			toggled: false,
			checked: true,
			toggleIcon: 'expand_more',
		},
		{
			name: 'Quality Nursing Woodbridge',
			childrenLocation: [
				{
					name: 'Child 01',
					checked: true,
				},
				{
					name: 'Child 02',
					checked: true,
				},
				{
					name: 'Child 03',
					checked: true,
				},
			],
			toggled: false,
			checked: true,
			toggleIcon: 'expand_more',
		},
	];
	public static courseDescriptionTemplate = `<b>Topic:</b><span> </span>
<br>
<br>
<b>Designed For:</b><span> </span>
<br>
<br>
<b>Learning Objectives:</b><span> </span>
<br>
<br>
<b>Overview:</b><span> </span>
<br>
<br>
<b>Format for Delivery:</b><span> </span>
`;

	public static groups: string[] = [
		'2018 lms group',
		'All Direct Care Staff',
		'All Staff',
		'Dietary',
		'Direct care staff',
		'Enviromental',
		'JHSC',
		'Leadership',
		'LMS group',
		'QRM group',
		'2021 Covid',
		'Frontline Group',
	];
	public static EventButtonsLive = [
		// {
		// 	name: 'ADD_TO_LP',
		// 	disabled: true,
		// 	infoHeader: 'ADD_TO_LEARNING_PATHWAY',
		// 	infoMsg: 'This is a generic message',
		// 	action: 'addToLP',
		// 	privilegeKey:
		// 		this.permissionHelper.LiveeventModule.ADD_LIVE_EVENT_LP,

		// 	actionFor: [2],
		// },
		{
			name: 'INACTIVATE',
			disabled: true,
			action: 'inactivate',
			privilegeKey: this.permissionHelper.LiveeventModule.INACTIVATE,
			actionFor: [2],
			actionForStatus: [2],
		},
		{
			name: 'ACTIVATE',
			disabled: true,
			action: 'activate',
			// privilegeKey: this.permissionHelper.LiveeventModule.ACTIVATE,
			actionFor: [2],
			actionForStatus: [3],
		},
		{
			name: 'EDIT',
			disabled: true,
			action: 'edit',
			privilegeKey: this.permissionHelper.LiveeventModule.EDIT,
			actionFor: [2],
			actionForStatus: [1,2]
		},
		{
			name: 'DELETE',
			disabled: true,
			action: 'delete',
			privilegeKey: this.permissionHelper.LiveeventModule.DELETE,
			actionFor: [2],
			actionForStatus: [1,2,3],
		},
	];
	public static eventTabSet: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'Available Events',
			data: {
				label: 'UPCOMING_LIVE_EVENTS',
				selected: true,
				// preventedActions: [],
			},
		},
		{
			id: 2,
			name: 'All Live Events',
			data: {
				label: 'MANAGE_LIVE_EVENTS',
				selected: false,
				// preventedActions: [],
			},
		},
	];

	public static categories: any = {
		errorcode: 0,
		errormsg: null,
		categories: [
			{
				categoryCount: 4,
				id: 1,
				name: 'Coronavirus COVID-19',
				courses: [
					{
						newCourse: true,
						assigned: true,
						history: true,
						id: 1,
						name: 'COVID 19: Recovered Status PDF (Jan 10. 2022)',
						desc: 'Recovered status',
						duration: '01:20:10',
						status: 1,
						selected: false,
					},
				],
				subCategory: [
					{
						subCategoryCount: 3,
						id: 9,
						name: 'Treatment 19',
						courses: [
							{
								newCourse: true,
								assigned: true,
								history: false,
								id: 5,
								name: 'COVID 19: (BUNDLE) Updated MOHLTC Directive on Immunization Policy and COVID 19 Vaccines (Jan 2022)',
								desc: 'Covid 19 Training',
								duration: '05:20:10',
								status: 1,
								selected: false,
							},
							{
								newCourse: true,
								assigned: false,
								history: true,
								id: 6,
								name: 'COVID-19 Vaccination - Kingston Health Sciences Centre (NO AUDIO) July 1,2021',
								desc: 'Covid 19 Training',
								duration: '03:10:18',
								status: 1,
								selected: false,
							},
							{
								newCourse: true,
								assigned: false,
								history: true,
								id: 9,
								name: 'Procedure PDF (Jan 10. 2022)',
								desc: 'Procedure status',
								duration: '02:50:10',
								status: 1,
								selected: false,
							},
						],
					},
				],
			},
			{
				categoryCount: 4,
				id: 2,
				name: 'Privacy and Security Training for Clinical End-Users',
				courses: [
					{
						newCourse: false,
						assigned: true,
						history: false,
						id: 3,
						name: 'Connecting Ontario EHR Security Overview Webinar for Viewing Organizations using ONE ID or Clinical Connect Accounts',
						desc: 'Security Overview',
						duration: '00:22:11',
						status: 1,
						selected: false,
					},
					{
						newCourse: true,
						assigned: true,
						history: true,
						id: 4,
						name: 'Connecting Ontario Module 1: Overview (Updated September. 2020)',
						desc: 'Overview',
						duration: '00:22:11',
						status: 1,
						selected: false,
					},
					{
						newCourse: false,
						assigned: true,
						history: true,
						id: 10,
						name: 'Covid Restrictions',
						desc: 'Restrictions',
						duration: '05:50:10',
						status: 1,
						selected: false,
					},
				],
				subCategory: [
					{
						subCategoryCount: 1,
						id: 2,
						name: 'Treatment-20',
						courses: [
							{
								newCourse: true,
								assigned: true,
								history: true,
								id: 8,
								name: 'Personal Health Information Protection Act 2004 - Video Guide for Training and Education Part 2',
								desc: 'Health Information',
								duration: '04:22:11',
								status: 1,
								selected: false,
							},
						],
					},
				],
			},
		],
	};

	public static ppTabSet = [
		{
			id: 1,
			name: 'PUBLISHED_POLICY',
			// TODO: Add Permissions
			// privilegeKey:
			// 	this.permissionHelper.resourceModule
			// 		.RESOURCE_MODULE_ALL_RESOURCES,
		},
		{
			id: 2,
			name: 'UNDER_REVIEW',
			// TODO: Add Permissions
			// privilegeKey:
			// 	this.permissionHelper.resourceModule
			// 		.RESOURCE_MODULE_SURGE_LIBRARY,
		},
		{
			id: 3,
			name: 'PENDING_aPPROVAL',
			// TODO: Add Permissions
			// privilegeKey:
			// 	this.permissionHelper.resourceModule
			// 		.RESOURCE_MODULE_MY_LIBRRARY,
		},
		{
			id: 4,
			name: 'PENDING_PUBLISH',
			// TODO: Add Permissions
			// privilegeKey:
			// 	this.permissionHelper.resourceModule
			// 		.RESOURCE_MODULE_SHARED_LIBRARY,
		},
	];

	public static courseCompletionDetailReportColumns: Array<CustomTableColumn> =
		[
			{
				columnName: 'COURSE_TITLE',
				bindKey: 'name',
				isShown: true,
				refName: 'name',
				sortable: true,
			},
			{
				columnName: 'DURATION',
				bindKey: 'duration',
				isShown: true,
				refName: 'duration',
				sortable: false,
			},
			{
				columnName: 'EDUCATION_GROUP',
				bindKey: 'educationGroup',
				isShown: true,
				refName: 'groups',
				sortable: false,
			},
			{
				columnName: 'DUE_DATE',
				bindKey: 'dueDate',
				isShown: true,
				refName: 'dueDate',
				sortable: false,
			},
			{
				columnName: 'PASSING_SCORE',
				bindKey: 'passingRate',
				refName: 'passingRate',
				sortable: false,
				isShown: true,
			},
		];

	public static assignedSurveyColumns: Array<CustomTableColumn> = [
		{
			columnName: 'SURVEY_NAME',
			bindKey: 'surveyName',
			isShown: true,
			refName: 'surveyName',
			sortable: true,
		},
		{
			columnName: 'EDUCATION_GROUP',
			bindKey: 'groupList',
			isShown: true,
			refName: 'groupList',
			sortable: false,
		},
		{
			columnName: 'FROM_DUE_DATE',
			bindKey: 'due_date',
			isShown: true,
			refName: 'dueDate',
			sortable: false,
		},
	];

	public static actionButtons = [
		{
			name: 'ASSIGN',
			disabled: true,
			infoMsg: 'ASSIGN_SELECTED_COURSES_LPS',
			action: resourceCenterActions.assign,
			// privilegeKey: this.permissionHelper.resourceModule.ASSIGN,
			privilegeKeys: [
				this.permissionHelper.resourceModule
					.SET_REQUIRED_EDUCATION_USER,
				this.permissionHelper.resourceModule
					.SET_REQUIRED_EDUCATION_DEPARTMENT,
			],
			logic: 'or',
			// actionFor: [
			// 	'Active',
			// 	'New Courses (30 days)',
			// 	'New Courses (1 year)',
			// 	'Hidden',
			// 	'Recommended',
			// ],
			actionFor: [1, 6, 7, 4, 5],
		},
		{
			name: 'UNASSIGN',
			disabled: false,
			infoMsg: 'UNASSIGN_SELECTED_COURSES',
			action: resourceCenterActions.unassign,
			privilegeKey: this.permissionHelper.resourceModule.UNASSIGN,
			// actionFor: ['Assigned'],
			actionFor: [8],
		},
		{
			name: 'EDIT_ASSIGNMENTS',
			disabled: false,
			infoMsg: 'VIEW_ASSIGNMENT_COURSE_LP',
			action: resourceCenterActions.viewAssignments,
			privilegeKey: this.permissionHelper.resourceModule.UNASSIGN,
			// actionFor: ['Assigned'],
			actionFor: [8],
		},
		{
			name: 'RECOMMEND',
			disabled: true,
			infoMsg: 'RECOMMEND_SELECTED_RESOURCES',
			action: resourceCenterActions.recommend,
			privilegeKey: this.permissionHelper.resourceModule.RECOMMEND,
			// actionFor: [
			// 	'Active',
			// 	'New Courses (30 days)',
			// 	'New Courses (1 year)',
			// 	'Hidden',
			// ],
			actionFor: [1, 6, 7, 4],
		},
		{
			name: 'REMOVE_RECOMMEND',
			disabled: true,
			infoMsg: 'REMOVE_RECOMMEND_RESOURCES',
			action: resourceCenterActions.removeRecommend,
			privilegeKey: this.permissionHelper.resourceModule.REMOVE_RECOMMEND,
			// actionFor: ['Recommended'],
			actionFor: [5],
		},
		{
			name: 'ADD_TO_LP',
			disabled: true,
			infoHeader: 'ADD_TO_LEARNING_PATHWAY',
			infoMsg: 'ADD_COURSES_TO_LP',
			action: resourceCenterActions.addToLP,
			privilegeKey: this.permissionHelper.resourceModule.ADD_COURCE_TO_LP,
			// actionFor: [
			// 	'Active',
			// 	'New Courses (30 days)',
			// 	'New Courses (1 year)',
			// 	'Inactive',
			// 	'Hidden',
			// 	'Recommended',
			// 	'Archived',
			// ],
			actionFor: [1, 6, 7, 2, 4, 5, 3],
		},
		{
			name: 'EDIT',
			disabled: true,
			infoMsg: 'EDIT_RESOURCE_MSG',
			action: resourceCenterActions.edit,
			privilegeKey: this.permissionHelper.resourceModule.EDIT,
			// actionFor: [
			// 	'Active',
			// 	'Inactive',
			// 	'New Courses (30 days)',
			// 	'New Courses (1 year)',
			// 	'Hidden',
			// 	'Recommended',
			// 	'Archived',
			// ],
			actionFor: [1, 2, 6, 7, 4, 5, 3],
		},
		{
			name: 'ARCHIVE',
			disabled: true,
			infoHeader: 'ARCHIVE',
			infoMsg: 'ARCHIVE_RESOURCES',
			action: resourceCenterActions.archive,
			privilegeKey: this.permissionHelper.resourceModule.ARCHIVE,
			// actionFor: [
			// 	'Active',
			// 	'Inactive',
			// 	'New Courses (30 days)',
			// 	'New Courses (1 year)',
			// 	'Hidden',
			// 	'Recommended',
			// ],
			actionFor: [1, 2, 6, 7, 4, 5],
		},
		{
			name: 'UNARCHIVE',
			disabled: true,
			infoHeader: resourceCenterActions.unarchive,
			infoMsg: 'UNARCHIVE_RESOURCES',
			action: 'unarchive',
			privilegeKey: this.permissionHelper.resourceModule.ARCHIVE,
			// actionFor: ['Archived'],
			actionFor: [3],
		},
		{
			name: 'ACTIVATE',
			disabled: true,
			infoMsg: 'ACTIVATE_RESOURCES',
			action: resourceCenterActions.activate,
			privilegeKey: this.permissionHelper.resourceModule.ACTIVATE,
			// actionFor: ['Inactive'],
			actionFor: [2],
		},
		{
			name: 'INACTIVATE',
			disabled: true,
			infoMsg: 'INACTIVATE_RESOURCES',
			action: resourceCenterActions.inactivate,
			privilegeKey: this.permissionHelper.resourceModule.INACTIVATE,
			// actionFor: [
			// 	'Active',
			// 	'New Courses (30 days)',
			// 	'New Courses (1 year)',
			// 	'Hidden',
			// 	'Recommended',
			// ],
			actionFor: [1, 6, 7, 4, 5],
		},
		{
			name: 'HIDE',
			disabled: true,
			infoMsg: 'HIDE_RESOURCES',
			action: resourceCenterActions.hide,
			privilegeKey: this.permissionHelper.resourceModule.HIDE,
			// actionFor: [
			// 	'Active',
			// 	'Inactive',
			// 	'New Courses (30 days)',
			// 	'New Courses (1 year)',
			// 	'Archived',
			// ],
			actionFor: [1, 2, 6, 7, 3],
		},
		{
			name: 'UNHIDE',
			disabled: true,
			infoMsg: 'UNHIDE_RESOURCES',
			action: resourceCenterActions.unhide,
			privilegeKey: this.permissionHelper.resourceModule.UNHIDE,
			// actionFor: ['Hidden'],
			actionFor: [4],
		},
		{
			name: 'DELETE',
			disabled: true,
			infoMsg: 'DELETE_RESOURCES',
			action: resourceCenterActions.delete,
			privilegeKey: this.permissionHelper.resourceModule.DELETE,
			// actionFor: [
			// 	'Active',
			// 	'Inactive',
			// 	'New Courses (30 days)',
			// 	'New Courses (1 year)',
			// 	'Hidden',
			// 	'Recommended',
			// 	'Archived',
			// ],
			actionFor: [1, 2, 6, 7, 4, 5, 3],
		},
	];

	public static requiredReportActionButtons = [
		{
			name: 'UNASSIGN',
			disabled: false,
			infoMsg: 'UNASSIGN_SELECTED_COURSES',
			action: resourceCenterActions.unassign,
			privilegeKey: this.permissionHelper.resourceModule.UNASSIGN,
		},
		{
			name: 'EDIT_ASSIGNMENTS',
			disabled: false,
			infoMsg: 'VIEW_ASSIGNMENT_COURSE_LP',
			action: resourceCenterActions.viewAssignments,
			privilegeKey: this.permissionHelper.resourceModule.UNASSIGN,
		},
	];
	public static commonAllowedActions: string[] = [
		resourceCenterActions.hide,
		resourceCenterActions.unhide,
		resourceCenterActions.viewDoc,
		resourceCenterActions.recommend,
		resourceCenterActions.assign,
		resourceCenterActions.unassign,
		resourceCenterActions.removeRecommend,
		resourceCenterActions.addToLP,
		resourceCenterActions.viewAssignments,
	];

	// Order needs to be maintained
	public dashboardInfoCards: Array<CourseInfoCard> = [
		{
			count: 0,
			type: 'COURSES',
			class: 'past-due',
			footerText: 'PAST_DUE',
			redirectTo: '/courses',
		},
		{
			count: 0,
			type: 'COURSES',
			class: 'coming-due',
			footerText: 'COMING_DUE',
			redirectTo: '/courses',
		},
		{
			count: 0,
			type: 'SURVEYS_HEADER',
			class: 'to-do',
			footerText: 'TO_DO',
			redirectTo: '/surveys',
		},
		{
			count: 0,
			type: 'SKILLS',
			class: 'approved',
			footerText: 'TO_DO',
			redirectTo: '/reports/skills/summary',
		},
		{
			count: 0,
			type: 'SKILLS',
			class: 'pending',
			footerText: 'SKILLS_PENDING_APPROVAL',
			redirectTo: '/reports/skills/summary',
			privilegeKey:
				AppConstants.permissionHelper.resourceModule
					.RESOURCE_MODULE_ALL_RESOURCES,
		},
	];

	public static tabSet: ResourceTab[] = [
		{
			id: 1,
			name: 'ALL_RESOURCES',
			privilegeKey:
				this.permissionHelper.resourceModule
					.RESOURCE_MODULE_ALL_RESOURCES,
			preventedActions: this.actionButtons
				.filter(
					(action) =>
						!this.commonAllowedActions.includes(action.action)
				)
				.map((action) => action.action),
		},
		{
			id: 2,
			name: 'SURGE_LIBRARY',
			privilegeKey:
				this.permissionHelper.resourceModule
					.RESOURCE_MODULE_SURGE_LIBRARY,
		},
		{
			id: 3,
			name: 'MY_LIBRARY',
			privilegeKey:
				this.permissionHelper.resourceModule
					.RESOURCE_MODULE_MY_LIBRRARY,
		},
		{
			id: 4,
			name: 'SHARED_LIBRARY',
			privilegeKey:
				this.permissionHelper.resourceModule
					.RESOURCE_MODULE_SHARED_LIBRARY,
		},
		{
			id: 5,
			name: 'DOCS',
			// privilegeKey:
			// 	this.permissionHelper.resourceModule.RESOURCE_MODULE_DOCS_SURGE,
			privilegeKey: this.permissionHelper.resourceModule.MODULE_NAME,
			preventedActions: [resourceCenterActions.edit],
		},
	];

	public static resourceLanguages = [
		{
			id: 1,
			language: 'English',
			translation: 'LANG_ENGLISH',
		},
		{
			id: 2,
			language: 'French',
			translation: 'LANG_FRENCH',
		},
		// {
		// 	id: 3,
		// 	language: 'Spanish',
		// 	translation: 'LANG_SPANISH',
		// },
	];

	public static documentLanguages = [
		{
			id: 1,
			language: 'English',
			translation: 'LANG_ENGLISH',
		},
		{
			id: 2,
			language: 'French',
			translation: 'LANG_FRENCH',
		},
	];

	public static moduleTypes = [
		{
			id: 3,
			name: 'MY_LIBRARY',
			checked: false,
		},
		{
			id: 2,
			name: 'SURGE_LIBRARY',
			checked: false,
		},
		{
			id: 4,
			name: 'SHARED_LIBRARY',
			checked: false,
		},
	];

	public static learningPathways = [
		'Learning Pathway 1',
		'Learning Pathway 2',
		'Learning Pathway 3',
		'Learning Pathway 4',
		'Learning Pathway 5',
	];

	public static exemptedUsers: Array<string> = [
		'User 01',
		'User 02',
		'User 03',
		'User 04',
		'User 05',
		'User 06',
	];

	public static tests = [
		{
			testId: 1,
			testName: 'Covid 19 Precautions',
			instructions: 'Please follow the instruction guide lines',
			filePath: 'D:\\SurgeLearning\\Documents\\Copy of MVP_v1.0.txt',
			otherLocation: true,
			sequencing: 1,
			requiredQuestions: true,
			questionList: [
				{
					id: 1,
					question: 'Did you Sanatize your hands',
					questionImageUrl:
						'D:\\SurgeLearning\\Documents\\washhands.jpg',
					type: 1,
					options: [
						{
							questionid: 1,
							value_id: 2,
							option: null,
							optionImageUrl:
								'D:\\SurgeLearning\\Documents\\washedhands.jpg',
							correctAnswer: true,
							paragrahAnswer: null,
						},
						{
							questionid: 1,
							value_id: 1,
							option: 'Not Washing',
							optionImageUrl: null,
							correctAnswer: false,
							paragrahAnswer: null,
						},
						{
							questionid: 1,
							value_id: 3,
							option: 'Never Wash Hands',
							optionImageUrl: null,
							correctAnswer: false,
							paragrahAnswer: null,
						},
					],
					isMandatory: true,
				},
				{
					id: 2,
					question: 'Did you Wear Mask',
					questionImageUrl:
						'D:\\SurgeLearning\\Documents\\wearmask.jpg',
					type: 2,
					options: [
						{
							questionid: 2,
							value_id: 2,
							option: 'Yes Weared Mask',
							optionImageUrl: null,
							correctAnswer: true,
							paragrahAnswer: null,
						},
						{
							questionid: 2,
							value_id: 1,
							option: null,
							optionImageUrl:
								'D:\\SurgeLearning\\Documents\\notwearinghands.jpg',
							correctAnswer: false,
							paragrahAnswer: null,
						},
						{
							questionid: 2,
							value_id: 3,
							option: 'Never Wear Mask',
							optionImageUrl: null,
							correctAnswer: false,
							paragrahAnswer: null,
						},
					],
					isMandatory: false,
				},
			],
		},
	];

	public static testList = [
		{
			id: 1,
			name: 'Covid 19 Precautions',
		},
		{
			id: 2,
			name: 'Test2',
		},
		{
			id: 3,
			name: 'Test3',
		},
	];

	public static educationGroupsColumns: Array<GroupsTableHeader> = [
		{
			columnName: 'TITLE',
			icon: 'unfold_more',
		},
		// {
		// 	columnName: 'Type',
		// 	icon: '',
		// },
		{
			columnName: 'VISIBLE_TO',
			icon: '',
		},
		{
			columnName: 'TRIGGER_GROUPS',
			icon: '',
		},
		{
			columnName: 'USERS',
			icon: '',
		},
		{
			columnName: 'IS_ORIENTATION_GROUP',
			icon: '',
		},
		{
			columnName: '',
			icon: '',
		},
	];

	public static assignmentsColumns: Array<AssignmentsTableHeader> = [
		// {
		// 	columnName: 'CLIENT_CODE',
		// 	icon: 'unfold_more',
		// },
		{
			columnName: 'EDUCATION_GROUP_DEPARTMENT',
			icon: '',
		},
		{
			columnName: 'USERS',
			icon: '',
		},
		{
			columnName: 'DUE_DATE',
			icon: '',
		},
		{
			columnName: 'PASSING_SCORE',
			icon: '',
		},
	];

	public static surveyAssignmentsColumns: Array<AssignmentsTableHeader> = [
		{
			columnName: 'EDUCATION_GROUP_DEPARTMENT',
			icon: '',
		},
		{
			columnName: 'USER_USER_CLASS',
			icon: '',
		},
		{
			columnName: 'SURVEY_PERIOD',
			icon: '',
		},
		{
			columnName: 'VIEW_ASSIGNMENTS_REQUIRED_DATE',
			icon: '',
		},
	];

	public static answerKey: { [key: number]: Array<number> | string } = {
		1: [2],
		2: [2, 3],
		3: 'some random answer',
		4: [1],
		5: [2, 3, 4],
		6: 'another random answer',
		7: [3],
		8: [1, 4],
		9: 'This is your correct answer',
	};
	public static manageUsersActionButtons: DropdownInterface[] = [
		{
			id: 1,
			name: 'UPLOAD_STATUS',
			data: {
				// popover: 'Some Detail',
			},
		},
		{
			id: 2,
			name: 'ADD_SKILL',
			data: {
				popover: 'Add Skill to User(s)',
			},
		},
		{
			id: 3,
			name: 'INACTIVATE_USERS',
			data: {
				// popover: 'Some Detail',
			},
		},
		{
			id: 4,
			name: 'ACTIVATE_USERS',
			data: {
				// popover: 'Some Detail',
			},
		},
		{
			id: 8,
			name: 'LINK_PROFILE',
			data: {
				popover: 'LINK_PROFILE_HEAD',
				privilegeKey:
					ManageUsersModulePrivilegesEnum.Manage_Users_Profile_Linking,
			},
		},
		{
			id: 5,
			name: 'CUSTOMISE',
			data: {
				popover: 'Customize Table',
			},
		},
		{
			id: 6,
			name: 'PDF',
			data: {
				// popover: 'PRINT_AS_PDF',
			},
		},
		{
			id: 7,
			name: 'EXCEL',
			data: {
				// popover: 'EXPORT_TO_CSV',
			},
		},
	];

	public static manageUsersActionButtonsMap: Map<
		number,
		Array<DropdownInterface>
	> = new Map([
		[
			1,
			[
				this.manageUsersActionButtons[0],
				this.manageUsersActionButtons[1],
				this.manageUsersActionButtons[2],
				this.manageUsersActionButtons[4],
				this.manageUsersActionButtons[5],
				this.manageUsersActionButtons[6],
				this.manageUsersActionButtons[7],
			],
		],
		[
			2,
			[
				this.manageUsersActionButtons[3],
				this.manageUsersActionButtons[5],
				this.manageUsersActionButtons[6],
				this.manageUsersActionButtons[7],
			],
		],
		[
			3,
			[
				this.manageUsersActionButtons[5],
				this.manageUsersActionButtons[6],
				this.manageUsersActionButtons[7],
			],
		],
	]);

	public static dashboardOnload: any = {
		errorcode: 0,
		errormsg: null,
		Surveys: {
			surveys: [
				{
					surveyId: 1,
					isPublished: 1,
					isActive: 1,
				},
				{
					surveyId: 2,
					isPublished: 1,
					isActive: 0,
				},
			],
		},

		Messages: {
			messages: [
				{
					messageID: 1,
					messageBody: 'Hi This is Surge Portal 1',
					isViewable: 1,
				},
				{
					messageID: 2,
					messageBody: 'Hi This is Surge Portal 2',
					isViewable: 0,
				},
			],
		},
		Skills: {
			skills: [
				{
					skillID: 1,
					skillName: 'Administer IV Meds',
					status: 1,
					isActive: 1,
					expiryDate: '31/03/2022',
				},
			],
		},
		CoursesPastComingDues: [
			{
				userId: 115,
				courseId: 466,
				courseName: 'RCSM P-10 ABUSE OR NEGLECT POLICY.pdf',
				parentCourseId: 1,
				courseDescription: '',
				courseDuration: '00:00:00',
				userGroupId: 46,
				groupName: 'Volunteer',
				requiredByDate: '2022-04-01T18:30:00.000+00:00',
				isMandatory: 1,
				isRecommended: 0,
				isCompleted: 0,
			},
			{
				userId: 115,
				courseId: 48136,
				courseName: 'Lifts and Transfers Policy',
				parentCourseId: null,
				courseDescription:
					'<p><strong>Topic:&nbsp;</strong></p>\\r\\n\\r\\n<p><strong>Designed for:</strong>&nbsp;</p>\\r\\n\\r\\n<p><strong>Learning Objectives:</strong></p>\\r\\n\\r\\n<p><strong>Overview:</strong>&nbsp;</p>\\r\\n\\r\\n<p><strong>Format for Delivery:</strong></p>\\r\\n',
				courseDuration: '00:30:00',
				userGroupId: 46,
				groupName: 'Volunteer',
				requiredByDate: '2022-03-30T18:30:00.000+00:00',
				isMandatory: 1,
				isRecommended: 0,
				isCompleted: 0,
			},
			{
				userId: 115,
				courseId: 48136,
				courseName: 'Lifts and Transfers Policy',
				parentCourseId: null,
				courseDescription:
					'<p><strong>Topic:&nbsp;</strong></p>\\r\\n\\r\\n<p><strong>Designed for:</strong>&nbsp;</p>\\r\\n\\r\\n<p><strong>Learning Objectives:</strong></p>\\r\\n\\r\\n<p><strong>Overview:</strong>&nbsp;</p>\\r\\n\\r\\n<p><strong>Format for Delivery:</strong></p>\\r\\n',
				courseDuration: '00:30:00',
				userGroupId: 46,
				groupName: 'Volunteer',
				requiredByDate: '2022-03-30T18:30:00.000+00:00',
				isMandatory: 0,
				isRecommended: 1,
				isCompleted: 0,
			},
		],
		Assessments: {
			assessments: [
				{
					assessmentID: 1,
					assessmentName: 'Assessment 1',
					isCompleted: 1,
					isActive: 1,
				},
				{
					assessmentID: 2,
					assessmentName: 'Assessment 2',
					isCompleted: 0,
					isActive: 1,
				},
			],
		},
		LiveEvents: {
			liveevents: [
				{
					eventID: 1,
					topic: 'Part 1 of 2 - Training for NEW Education Coordinators',
					hostedBy: 'Amanda France',
				},
				{
					eventID: 2,
					topic: 'Part 2 of 2 - Training for NEW Education Coordinators',
					hostedBy: 'Amanda France2',
				},
			],
		},
	};
	public static docTypes = [
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/vnd.ms-excel',
		'application/pdf',
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	];
	public static reportTypes: Array<ReportTabs> = [
		{
			id: 1,
			name: 'USER',
			privilegeKey: ReportModulePrivilegesEnum.MODULE_NAME,
			url: 'reports/user/overview',
			reports: [
				{
					id: 1,
					name: 'DASHBOARD_REPORT',
					url: 'reports/user/dashboard-report',
					altUrl: '/reports/user/dashboard-report',
					icon: 'assets/icons/dashboard-report-icon.svg',
					highlightingReports: ['reports/user/dashboard-report'],
				},
				{
					id: 2,
					name: 'MONTHLY_EDUCATION_SUMMARY_REPORT',
					url: 'reports/user/monthly',
					altUrl: '/reports/user/monthly',
					icon: 'assets/icons/monthly-education-report.svg',
					highlightingReports: ['reports/user/monthly'],
				},
				{
					id: 3,
					name: 'USER_EDUCATION_STATUS',
					url: 'reports/user/education-status',
					altUrl: '/reports/user/education-status',
					icon: 'assets/icons/education-status-report.svg',
					highlightingReports: [
						'reports/user/education-status',
						'reports/user/education/details',
					],
				},
				{
					id: 4,
					name: 'USER_EDUCATION_HISTORY',
					url: 'reports/user/education-history',
					altUrl: '/reports/user/education-history',
					icon: 'assets/icons/education-history-report.svg',
					highlightingReports: [
						'reports/user/education-history',
						'reports/user/education/history',
					],
				},
				{
					id: 5,
					name: 'USER_EDUCATION_SUMMARY',
					url: 'reports/user/education-summary',
					altUrl: '/reports/user/education-summary',
					icon: 'assets/icons/education-summary-report.svg',
					highlightingReports: ['reports/user/education-summary'],
				},
				{
					id: 6,
					name: 'EDUCATION_ASSIGNED_TO_USER',
					url: 'reports/user/user-assignment',
					altUrl: '/reports/user/user-assignment',
					icon: 'assets/icons/user-education-assignment-report.svg',
					highlightingReports: ['reports/user/user-assignment'],
				},
			],
		},
		{
			id: 2,
			name: 'COURSE',
			privilegeKey: ReportModulePrivilegesEnum.MODULE_NAME,
			url: 'reports/course/course-completion',
			reports: [
				{
					id: 1,
					name: 'COURSE_COMPLETION',
					url: 'reports/course/course-completion',
					altUrl: '/reports/course/course-completion',
					icon: 'assets/icons/course-completion-report.svg',
				},
				{
					id: 2,
					name: 'REQUIRED_COMPLETION',
					url: 'reports/course/required-education-completion',
					altUrl: '/reports/course/required-education-completion',
					icon: 'assets/icons/required-education-report.svg',
				},
				{
					id: 3,
					name: 'REQUIRED_REPORT',
					url: 'reports/course/required-report',
					altUrl: '/reports/course/required-report',
					icon: 'assets/icons/required-report.svg',
				},
				{
					id: 4,
					name: 'REQUIRED_HISTORY',
					url: 'reports/course/required-history',
					altUrl: '/reports/course/required-history',
					icon: 'assets/icons/required-history-report.svg',
				},
				{
					id: 5,
					name: 'UTILIZATION_REPORT',
					url: 'reports/course/utilization-report',
					altUrl: '/reports/course/utilization-report',
					icon: 'assets/icons/utilization-report.svg',
				},
			],
		},
		{
			id: 3,
			name: 'ENTERPRISE',
			privilegeKey:
				ReportModulePrivilegesEnum.LMS_REPORTS_ENTERPRISE_REPORT,
			checkLocation: true,
			url: 'reports/enterprise/enterprise-required',
			reports: [
				{
					id: 1,
					name: 'ENTERPRISE_REQUIRED',
					privilegeKey:
						ReportModulePrivilegesEnum.LMS_REPORTS_ENTERPRISE_REQUIRED_REPORT,
					url: 'reports/enterprise/enterprise-required',
					icon: 'assets/icons/enterprise-required.svg',
				},
				{
					id: 2,
					name: 'ENTERPRISE_REQUIRED_EDUCATION_COMPLETION',
					privilegeKey:
						ReportModulePrivilegesEnum.LMS_REPORTS_ENTERPRISE_REQUIRED_EDUCATION_REPORT,
					url: 'reports/enterprise/enterprise-required-education-completion',
					icon: 'assets/icons/enterprise-required-education-completion.svg',
				},
				{
					id: 3,
					name: 'ENTERPRISE_COURSE_COMPLETION',
					privilegeKey:
						ReportModulePrivilegesEnum.LMS_REPORTS_ENTERPRISE_COURSE_COMPLETION_REPORT,
					url: 'reports/enterprise/enterprise-course-completion',
					icon: 'assets/icons/enterprise-course-completion.svg',
				},
				{
					id: 4,
					name: 'MULTIPLE_COURSE_COMPLETION',
					privilegeKey:
						ReportModulePrivilegesEnum.LMS_REPORTS_ENTERPRISE_MULTIPLE_COURSE_COMPLETION_REPORT,
					url: 'reports/enterprise/multiple-course-completion',
					icon: 'assets/icons/enterprise-course-completion.svg',
				},
			],
		},
		{
			id: 4,
			name: 'QRM',
			privilegeKey: ReportModulePrivilegesEnum.QRM_REPORTS,
			url: 'reports/quality/audit-summary',
			reports: [
				{
					id: 1,
					name: 'AUDIT_SUMMARY_REPORT',
					privilegeKey:
						ReportModulePrivilegesEnum.QRM_REPORT_AUDIT_SUMMARY_REPORT,
					url: 'reports/quality/audit-summary',
					icon: 'assets/icons/qrm-audit-summary.svg',
				},
				{
					id: 2,
					name: 'DASHBOARD_REPORT',
					privilegeKey:
						ReportModulePrivilegesEnum.QRM_REPORT_DASHBOARD_REPORTS,
					url: 'reports/quality/dashboard',
					icon: 'assets/icons/qrm-dashboard.svg',
				},
				{
					id: 3,
					name: 'USER_STATUS_REPORT',
					url: 'reports/quality/user-status',
					privilegeKey:
						ReportModulePrivilegesEnum.QRM_REPORT_USER_STATUS_REPORT,
					icon: 'assets/icons/qrm-user-status.svg',
				},
				{
					id: 4,
					name: 'ACTION_SUMMARY_REPORT',
					privilegeKey:
						ReportModulePrivilegesEnum.QRM_REPORT_ACTION_SUMMARY_REPORT,
					url: 'reports/quality/action-summary',
					icon: 'assets/icons/qrm-action-summary.svg',
				},
			],
		},
		{
			id: 5,
			name: 'SKILLS',
			privilegeKey: ReportModulePrivilegesEnum.MODULE_NAME,
			url: 'reports/skills/summary',
			reports: [
				{
					id: 1,
					name: 'SKILLS_SUMMARY',
					privilegeKey:
						ReportModulePrivilegesEnum.LMS_REPORTS_SKILLS_SUMMARY_REPORT,
					url: 'reports/skills/summary',
					icon: 'assets/icons/skill-report-icon.svg',
				},
				{
					id: 2,
					name: 'SKILLS_COMING_DUE',
					privilegeKey:
						ReportModulePrivilegesEnum.LMS_REPORTS_SKILL_COMING_DUE_REPORT,
					url: 'reports/skills/coming-due',
					icon: 'assets/icons/skill-report-icon.svg',
				},
				{
					id: 3,
					name: 'SKILLS_PENDING_APPROVAL',
					privilegeKey:
						ReportModulePrivilegesEnum.LMS_REPORTS_sKILLS_PENDING_APPROVAL_REPORT,
					url: 'reports/skills/pending-approval',
					icon: 'assets/icons/skill-report-icon.svg',
				},
			],
		},
		{
			id: 6,
			name: 'OTHERS',
			privilegeKey: ReportModulePrivilegesEnum.MODULE_NAME,
			url: 'other-reports',
			reports: [
				{
					id: 1,
					name: 'SURVEY_SUMMARY_REPORT',
					privilegeKey:
						SurveysModulePrivilegesEnum.SURVEY_MANAGE_SURVEY,
					url: 'reports/other/survey-summary',
					icon: 'assets/icons/survey-report.svg',
				},
				{
					id: 2,
					name: 'INDIVIDUAL_SURVEY_REPORT',
					url: 'reports/other/individual-survey',
					privilegeKey:
						SurveysModulePrivilegesEnum.SURVEY_MANAGE_SURVEY,
					icon: 'assets/icons/survey-report.svg',
				},
				{
					id: 3,
					name: 'PROGRAM_EVALUATION_REPORT',
					privilegeKey: ReportModulePrivilegesEnum.MODULE_NAME,
					url: 'reports/other/program-evaluation',
					icon: 'assets/icons/program-evaluation-report.svg',
				},
			],
		},
	];

	public static summaryReport: Array<OverviewGraphModel> = [
		{
			id: 1,
			name: 'OVERALL_COMPLETION_RATE_OF_COURSES',
			units: '%',
			innerStrokeColor: '#E8F0F9',
			outerStrokeColor: '#2F68B7',
			outerStrokeGradientStopColor: '#56A2D7',
			showTitle: true,
			total: 0,
			value: 0,
			showPercent: true,
			showTotal: false,
			showSubTitle: false,
			subTitle: '',
			detailRoute: '/reports/course/required-education-completion',
			queryParams: {},
		},
		{
			id: 2,
			name: 'USERS_WITH_ALL_COMPLETION',
			units: '',
			percent: 0,
			innerStrokeColor: '#1CD17F1A',
			outerStrokeColor: '#2F68B7',
			outerStrokeGradientStopColor: '#62CD86',
			showTitle: false,
			total: 0,
			value: 0,
			showPercent: false,
			showTotal: true,
			showSubTitle: true,
			subTitle: '',
			detailRoute: '/reports/user/dashboard-report',
			queryParams: {
				completedStatus: 1,
			},
		},
		{
			id: 3,
			name: 'USERS_WITH_NO_LOGIN',
			units: '',
			percent: 100,
			innerStrokeColor: '#66666619',
			outerStrokeColor: '#3A3A3A',
			outerStrokeGradientStopColor: '#999999',
			showTitle: false,
			total: 0,
			value: 0,
			showPercent: false,
			showTotal: false,
			showSubTitle: true,
			subTitle: '',
			detailRoute: '/reports/user/dashboard-report',
			queryParams: {
				usersWithNoLoginThisyear: 1,
			},
		},
		{
			id: 4,
			name: 'USERS_WITH_EDUCATION_OVERDUE',
			units: '',
			innerStrokeColor: '#FFB2531A',
			outerStrokeColor: '#E77F70',
			outerStrokeGradientStopColor: '#F4B464',
			showTitle: false,
			total: 0,
			value: 0,
			showPercent: false,
			showSubTitle: true,
			showTotal: false,
			subTitle: '',
			detailRoute: '/reports/user/dashboard-report',
			queryParams: {
				completedStatus: 2,
			},
		},
		{
			id: 5,
			name: 'USERS_WITH_NO_COURSE_COMPLETED',
			units: '',
			innerStrokeColor: '#E8F0F9',
			outerStrokeColor: '#2F68B7',
			outerStrokeGradientStopColor: '#56A2D7',
			showTitle: false,
			total: 0,
			value: 0,
			showPercent: false,
			showSubTitle: true,
			showTotal: false,
			subTitle: '',
			detailRoute: '/reports/user/dashboard-report',
			queryParams: {
				completedStatus: 4,
			},
		},
		{
			id: 6,
			name: 'USERS_PART_OF_ORIENTATION',
			units: '',
			innerStrokeColor: '#1CD17F1A',
			outerStrokeColor: '#2F68B7',
			outerStrokeGradientStopColor: '#62CD86',
			showTitle: false,
			total: 0,
			value: 0,
			showPercent: false,
			showSubTitle: true,
			showTotal: false,
			subTitle: '',
			detailRoute: '/reports/user/dashboard-report',
			queryParams: {
				orientationMode: 1,
			},
		},
		{
			id: 7,
			name: 'USERS_COMPLETED_ORIENTATION',
			units: '',
			innerStrokeColor: '#66666619',
			outerStrokeColor: '#3A3A3A',
			outerStrokeGradientStopColor: '#999999',
			showTitle: false,
			total: 0,
			value: 0,
			showPercent: false,
			showSubTitle: true,
			showTotal: false,
			subTitle: '',
			detailRoute: '/reports/user/dashboard-report',
			queryParams: {
				orientationMode: 0,
				completedStatus: 1,
			},
		},
	];
	public static months: Array<{ id: number; name: string }> = [
		{ id: 1, name: 'JANUARY' },
		{ id: 2, name: 'FEBRUARY' },
		{ id: 3, name: 'MARCH' },
		{ id: 4, name: 'APRIL' },
		{ id: 5, name: 'MAY' },
		{ id: 6, name: 'JUNE' },
		{ id: 7, name: 'JULY' },
		{ id: 8, name: 'AUGUST' },
		{ id: 9, name: 'SEPTEMBER' },
		{ id: 10, name: 'OCTOBER' },
		{ id: 11, name: 'NOVEMBER' },
		{ id: 12, name: 'DECEMBER' },
	];
	public static shortMonths = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	public static competencyData = [
		{ id: 1, name: 'Somewhat Competent' },
		{ id: 2, name: 'Competent' },
		{ id: 3, name: 'Very Competent' },
	];
	public static surveyActions = [
		{
			id:1,
			name: 'ASSIGN',
			privilegeKey:
				AppConstants.permissionHelper.SurveysModule
					.SURVEY_STATUS_ASSIGN,
			actionFor: ['Active'],
		},
		{
			id:2,
			name: 'UNASSIGN',
			privilegeKey:
				AppConstants.permissionHelper.SurveysModule
					.SURVEY_STATUS_UNASSIGN,
			actionFor: ['Assigned', 'Closed'],
		},
		{
			id:3,
			name: 'PENDING_PUBLISH',
			privilegeKey:
				AppConstants.permissionHelper.SurveysModule
					.SURVEY_PENDING_PUBLISH,
			actionFor: [''],
		},
		{
			id:4,
			name: 'ACTIVATE',
			privilegeKey:
				AppConstants.permissionHelper.SurveysModule
					.SURVEY_STATUS_ACTIVE,
			actionFor: ['Retired', 'Inactive', 'Pending Publish'],
		},
		{
			id:5,
			name: 'INACTIVATE',
			privilegeKey:
				AppConstants.permissionHelper.SurveysModule
					.SURVEY_STATUS_INACTIVE,
			actionFor: ['Active'],
		},
		{
			id:6,
			name: 'EDIT',
			privilegeKey:
				AppConstants.permissionHelper.SurveysModule.SURVEY_STATUS_EDIT,
			// actionFor: ['Pending Publish', 'Inactive'],
			actionFor: ['Inactive', 'Active'],
		},
		{
			id:7,
			name: 'RETIRE',
			privilegeKey:
				AppConstants.permissionHelper.SurveysModule
					.SURVEY_STATUS_RETIRE,
			actionFor: ['Inactive', 'Active'],
		},
		{
			id: 8,
			name: 'DELETE',
			privilegeKey:
				AppConstants.permissionHelper.SurveysModule
					.SURVEY_PENDING_PUBLISH,
			actionFor: ['Pending Publish'],
		},
		{
			id: 9,
			name: 'VIEW_ASSIGNMENTS',
			privilegeKey:
				AppConstants.permissionHelper.SurveysModule
					.SURVEY_STATUS_UNASSIGN,
			actionFor: ['Assigned', 'Closed'],
		},
	];

	public static surveyStatus = [
		{
			id: 1,
			name: 'ACTIVE',
			filter: 'ACTIVE',
		},
		{
			id: 2,
			name: 'INACTIVE',
			filter: 'IN_ACTIVE',
		},
		{
			id: 3,
			name: 'ASSIGNED',
			filter: 'ASSIGNED',
		},
		{
			id: 6,
			name: 'Retired',
			filter: 'RETIRED',
		},
		{
			id: 7,
			name: 'CLOSED',
			filter: 'CLOSED',
		},
	];
	public static qrmActive = 1;
	public static qrmInActive = 2;
	public static qrmUnderconstruction = 3;
	public static qrmScheduled = 4;
	public static requiredEducationGraphs: {
		name: string;
		percent: number;
		value: number;
		innerStrokeColor: string;
		outerStrokeColor: string;
		outerStrokeGradientStopColor: string;
	}[] = [
		{
			name: 'AVERAGE_COMPLETION',
			percent: 0,
			value: 0,
			innerStrokeColor: '#E8F0F9',
			outerStrokeColor: '#2F68B7',
			outerStrokeGradientStopColor: '#56A2D7',
		},
		{
			name: 'TOTAL_COMPLETION',
			percent: 0,
			value: 0,
			innerStrokeColor: '#1CD17F1A',
			outerStrokeColor: '#2F68B7',
			outerStrokeGradientStopColor: '#62CD86',
		},
	];
	public static resourceDescriptionContents: string = `
	<div>
  		<strong>L0</strong>
	</div>
	<div>
  		<strong class="courseName">val0</strong>
	</div>
	<div>
  	<strong> L1: val1 </strong>
	</div>
	<div>
  		<strong>L2: val2</strong>
	</div>
	`;

	public static helpCategoryIcons = [
		{
			categoryNameKey: 'HELP_GENERAL',
			categoryName: 'General Documents',
			iconUrl: '../../../../assets/images/help-icons/general_documents.svg',
		},
		{
			categoryNameKey: 'HELP_LEARNING_MANAGEMENT_SYSTEM',
			categoryName: 'Learning Management System',
			iconUrl: '../../../../assets/images/help-icons/LMS.png',
		},
		{
			categoryNameKey: 'HELP_POLICY_PROFESSIONAL',
			categoryName: 'Policy Professional',
			iconUrl: '../../../../assets/images/help-icons/privacy-policy.png',
		},
		{
			categoryNameKey: 'HELP_QUALITY_RISK_MANAGEMENT',
			categoryName: 'Quality and Risk Management',
			iconUrl: '../../../../assets/images/help-icons/QRM.png',
		},
		{
			categoryNameKey: 'HELP_SYSTEM_REQUIREMENTS',
			categoryName: 'System Requirements',
			iconUrl:
				'../../../../assets/images/help-icons/new_settings.svg',
		},
		{
			categoryNameKey: 'HELP_EXTERNAL_LINKS',
			categoryName: 'External Links',
			iconUrl: '',
		},
		{
			categoryNameKey: 'HELP_VERSION_RELEASE',
			categoryName: 'Version Release',
			iconUrl: '',
		},
		{
			categoryNameKey: 'HELP_DASHBOARD',
			categoryName: 'Dashboard',
			iconUrl: '../../../../assets/images/help-icons/dashboard.png',
		},
		{
			categoryNameKey: 'HELP_FAQ',
			categoryName: 'FAQ',
			iconUrl: '../../../../assets/images/help-icons/faq.png',
		},
		{
			categoryNameKey: 'HELP_LIVE_EVENTS',
			categoryName: 'Live Event',
			iconUrl: '../../../../assets/images/help-icons/Live-events.png',
		},
		{
			categoryNameKey: 'HELP_REPORTS',
			categoryName: 'Reports',
			iconUrl: '../../../../assets/images/help-icons/reports.png',
		},
		{
			categoryNameKey: 'HELP_SURVEYS',
			categoryName: 'Surveys',
			iconUrl: '../../../../assets/images/help-icons/survey.png',
		},
		{
			categoryNameKey: 'HELP_TUTORIALS',
			categoryName: 'Tutorials',
			iconUrl: '../../../../assets/images/help-icons/Tutorial.png',
		},
	];
	public static qrmTypes: { id: number; name: string }[] = [
		{
			id: 0,
			name: 'Standard QRM',
		},
		{
			id: 1,
			name: 'Part QRM',
		},
	];
	public static settingsTypes = [
		{
			typeId: 1,
			typeName: 'select',
		},
		{
			typeId: 2,
			typeName: 'checkbox',
		},
		{
			typeId: 3,
			typeName: 'text',
		},
		{
			typeId: 4,
			typeName: 'date',
		},
	];

	public static sampleSequence = {
		sections: [
			{
				sectionId: 0,
				sectionName: 'Section 1',
				sectionQuestions: [
					{
						questionId: 0,
						questionName: 'This is Question 1',
						questionOptions: [
							{
								id: 1,
								name: 'Option 1',
							},
							{
								id: 2,
								name: 'Option 2',
							},
							{
								id: 3,
								name: 'Option 3',
							},
						],
						questionsForLogic: [
							{
								questionId: 1,
								questionName: 'This is Question 2',
								questionOptions: [
									{
										id: 1,
										name: 'Q2 Option 1',
									},
									{
										id: 2,
										name: 'Q2 Option 2',
									},
									{
										id: 3,
										name: 'Q2 Option 3',
									},
								],
							},
							{
								questionId: 2,
								questionName: 'This is Question 3',
								questionOptions: [
									{
										id: 1,
										name: 'Q3 Option 1',
									},
									{
										id: 2,
										name: 'Q3 Option 2',
									},
									{
										id: 3,
										name: 'Q3 Option 3',
									},
								],
							},
							{
								questionId: 3,
								questionName: 'This is Question 4',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 4,
								questionName: 'This is Question 5',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 5,
								questionName: 'This is Question 6',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 6,
								questionName: 'This is Question 7',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
						],
						questionLogic: [],
					},
					{
						questionId: 1,
						questionName: 'This is Question 2',
						questionOptions: [
							{
								id: 1,
								name: 'Q2 Option 1',
							},
							{
								id: 2,
								name: 'Q2 Option 2',
							},
							{
								id: 3,
								name: 'Q2 Option 3',
							},
						],
						questionsForLogic: [
							{
								questionId: 0,
								questionName: 'This is Question 1',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 2,
								questionName: 'This is Question 3',
								questionOptions: [
									{
										id: 1,
										name: 'Q3 Option 1',
									},
									{
										id: 2,
										name: 'Q3 Option 2',
									},
									{
										id: 3,
										name: 'Q3 Option 3',
									},
								],
							},
							{
								questionId: 3,
								questionName: 'This is Question 4',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 4,
								questionName: 'This is Question 5',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 5,
								questionName: 'This is Question 6',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 6,
								questionName: 'This is Question 7',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
						],
						questionLogic: [],
					},
					{
						questionId: 2,
						questionName: 'This is Question 3',
						questionOptions: [
							{
								id: 1,
								name: 'Q3 Option 1',
							},
							{
								id: 2,
								name: 'Q3 Option 2',
							},
							{
								id: 3,
								name: 'Q3 Option 3',
							},
						],
						questionsForLogic: [
							{
								questionId: 0,
								questionName: 'This is Question 1',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 1,
								questionName: 'This is Question 2',
								questionOptions: [
									{
										id: 1,
										name: 'Q2 Option 1',
									},
									{
										id: 2,
										name: 'Q2 Option 2',
									},
									{
										id: 3,
										name: 'Q2 Option 3',
									},
								],
							},
							{
								questionId: 3,
								questionName: 'This is Question 4',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 4,
								questionName: 'This is Question 5',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 5,
								questionName: 'This is Question 6',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 6,
								questionName: 'This is Question 7',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
						],
						questionLogic: [],
					},
					{
						questionId: 6,
						questionName: 'This is Question 7',
						questionOptions: [
							{
								id: 1,
								name: 'Option 1',
							},
							{
								id: 2,
								name: 'Option 2',
							},
							{
								id: 3,
								name: 'Option 3',
							},
						],
						questionsForLogic: [
							{
								questionId: 0,
								questionName: 'This is Question 1',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 1,
								questionName: 'This is Question 2',
								questionOptions: [
									{
										id: 1,
										name: 'Q2 Option 1',
									},
									{
										id: 2,
										name: 'Q2 Option 2',
									},
									{
										id: 3,
										name: 'Q2 Option 3',
									},
								],
							},
							{
								questionId: 2,
								questionName: 'This is Question 3',
								questionOptions: [
									{
										id: 1,
										name: 'Q3 Option 1',
									},
									{
										id: 2,
										name: 'Q3 Option 2',
									},
									{
										id: 3,
										name: 'Q3 Option 3',
									},
								],
							},
							{
								questionId: 3,
								questionName: 'This is Question 4',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 4,
								questionName: 'This is Question 5',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 5,
								questionName: 'This is Question 6',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
						],
						questionLogic: [
							{
								logic: 1,
								option: 1,
								question: 3,
								questions: [
									{
										questionId: 3,
										questionName: 'This is Question 4',
									},
								],
							},
							{
								logic: 2,
								option: 2,
								question: 4,
								questions: [
									{
										questionId: 4,
										questionName: 'This is Question 5',
									},
								],
							},
						],
					},
				],
			},
			{
				sectionId: 0,
				sectionName: 'Section 2',
				sectionQuestions: [
					{
						questionId: 3,
						questionName: 'This is Question 4',
						questionOptions: [
							{
								id: 1,
								name: 'Option 1',
							},
							{
								id: 2,
								name: 'Option 2',
							},
							{
								id: 3,
								name: 'Option 3',
							},
						],
						questionsForLogic: [
							{
								questionId: 0,
								questionName: 'This is Question 1',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 1,
								questionName: 'This is Question 2',
								questionOptions: [
									{
										id: 1,
										name: 'Q2 Option 1',
									},
									{
										id: 2,
										name: 'Q2 Option 2',
									},
									{
										id: 3,
										name: 'Q2 Option 3',
									},
								],
							},
							{
								questionId: 2,
								questionName: 'This is Question 3',
								questionOptions: [
									{
										id: 1,
										name: 'Q3 Option 1',
									},
									{
										id: 2,
										name: 'Q3 Option 2',
									},
									{
										id: 3,
										name: 'Q3 Option 3',
									},
								],
							},
							{
								questionId: 4,
								questionName: 'This is Question 5',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 5,
								questionName: 'This is Question 6',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 6,
								questionName: 'This is Question 7',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
						],
						questionLogic: [
							{
								logic: 1,
								option: 1,
								question: 5,
								questions: [
									{
										questionId: 5,
										questionName: 'This is Question 6',
									},
								],
							},
						],
					},
					{
						questionId: 4,
						questionName: 'This is Question 5',
						questionOptions: [
							{
								id: 1,
								name: 'Option 1',
							},
							{
								id: 2,
								name: 'Option 2',
							},
							{
								id: 3,
								name: 'Option 3',
							},
						],
						questionsForLogic: [
							{
								questionId: 0,
								questionName: 'This is Question 1',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 1,
								questionName: 'This is Question 2',
								questionOptions: [
									{
										id: 1,
										name: 'Q2 Option 1',
									},
									{
										id: 2,
										name: 'Q2 Option 2',
									},
									{
										id: 3,
										name: 'Q2 Option 3',
									},
								],
							},
							{
								questionId: 2,
								questionName: 'This is Question 3',
								questionOptions: [
									{
										id: 1,
										name: 'Q3 Option 1',
									},
									{
										id: 2,
										name: 'Q3 Option 2',
									},
									{
										id: 3,
										name: 'Q3 Option 3',
									},
								],
							},
							{
								questionId: 3,
								questionName: 'This is Question 4',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 5,
								questionName: 'This is Question 6',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 6,
								questionName: 'This is Question 7',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
						],
						questionLogic: [
							{
								logic: 2,
								option: 1,
								question: 5,
								questions: [
									{
										questionId: 5,
										questionName: 'This is Question 6',
									},
								],
							},
						],
					},
				],
			},
			{
				sectionId: 0,
				sectionName: 'Section 3',
				sectionQuestions: [
					{
						questionId: 5,
						questionName: 'This is Question 6',
						questionOptions: [
							{
								id: 1,
								name: 'Option 1',
							},
							{
								id: 2,
								name: 'Option 2',
							},
							{
								id: 3,
								name: 'Option 3',
							},
						],
						questionsForLogic: [
							{
								questionId: 0,
								questionName: 'This is Question 1',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 1,
								questionName: 'This is Question 2',
								questionOptions: [
									{
										id: 1,
										name: 'Q2 Option 1',
									},
									{
										id: 2,
										name: 'Q2 Option 2',
									},
									{
										id: 3,
										name: 'Q2 Option 3',
									},
								],
							},
							{
								questionId: 2,
								questionName: 'This is Question 3',
								questionOptions: [
									{
										id: 1,
										name: 'Q3 Option 1',
									},
									{
										id: 2,
										name: 'Q3 Option 2',
									},
									{
										id: 3,
										name: 'Q3 Option 3',
									},
								],
							},
							{
								questionId: 3,
								questionName: 'This is Question 4',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 4,
								questionName: 'This is Question 5',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
							{
								questionId: 6,
								questionName: 'This is Question 7',
								questionOptions: [
									{
										id: 1,
										name: 'Option 1',
									},
									{
										id: 2,
										name: 'Option 2',
									},
									{
										id: 3,
										name: 'Option 3',
									},
								],
							},
						],
						questionLogic: [],
					},
				],
			},
		],
	};

	public static emailQueueFilter: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'LMS - Send Feed back',
			data: {
				filterName: 'LMS_FEEDBACK',
			},
		},
		{
			id: 2,
			name: 'LMS - Request Support',
			data: {
				filterName: 'LMS_REQUEST',
			},
		},
		{
			id: 3,
			name: 'LMS - Course Completion Verification Feedback',
			data: {
				filterName: 'LMS_CCVF',
			},
		},
		{
			id: 4,
			name: 'LMS - This week on Surge',
			data: {
				filterName: 'LMS_TWOS',
			},
		},
		// {
		// 	id: 5,
		// 	name: 'LMS - Broadcast message',
		// 	data: {
		// 		filterName: 'LMS_BROADCAST',
		// 	},
		// },
		{
			id: 6,
			name: 'LMS - Messageboard',
			data: {
				filterName: 'LMS_MESSAGING',
			},
		},
		{
			id: 7,
			name: 'LMS - Manage user “Email username”',
			data: {
				filterName: 'LMS_MANAGE_USER',
			},
		},
		{
			id: 8,
			name: 'LMS - Supervisor Email about the report',
			data: {
				filterName: 'LMS_SUPERVISOR',
			},
		},
		{
			id: 9,
			name: 'Badges and Rewards Emails',
			data: {
				filterName: 'BADGES_AND_REWARDS',
			},
		},
		{
			id: 10,
			name: 'SURVEY',
			data: {
				filterName: 'SURVEY',
			},
		},
		{
			id: 11,
			name: 'LMS - Manager Report',
			data: {
				filterName: 'LMS_MANAGER_REPORT',
			},
		},
		// {
		// 	id: 3,
		// 	name: 'QRM',
		// 	data: {
		// 		filterName: 'QRM',
		// 	},
		// },
		// {
		// 	id: 4,
		// 	name: 'Policy',
		// 	data: {
		// 		filterName: 'POLICY_PROFESSIONAL',
		// 	},
		// },
		{
			id: 11,
			name: 'SKILLS',
			data: {
				filterName: 'SKILLS',
			},
		},
		{
			id: 12,
			name: 'Live event',
			data: {
				filterName: 'LIVE_EVENTS',
			},
		},
		{
			id: 13,
			name: 'Others',
			data: {
				filterName: 'OTHERS',
			},
		},
		{
			id: 14,
			name: 'LMS - Learning path live event',
			data: {
				filterName: 'LMS_LPLE',
			},
		},
		{
			id: 15,
			name: 'LMS - Forgot Password',
			data: {
				filterName: 'LMS_FP',
			},
		},
	];
}
